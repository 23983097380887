import { createQueryKeys } from '@lukemorales/query-key-factory'
import { authorizationProfileApi } from '@js/api/authorizationProfileApi'

export const authorizationProfiles = createQueryKeys('authorizationProfiles', {
  all: null,
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            authorizationProfileApi.fetchAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            authorizationProfileApi
              .fetchInheritedAssignedUsers(id)
              .then((response) => response.data),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            authorizationProfileApi.fetchAssignedUserGroups(id).then((response) => response.data),
        },
      },
    }
  },
})
