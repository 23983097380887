import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { statusApi } from '@js/api/statusApi'
import type { Status } from '@js/model/status'

export const statuses = createQueryKeys('statuses', {
  all: {
    queryKey: null,
    queryFn: () => statusApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRefOrGetter<Status['id'] | undefined>) => ({
    queryKey: [id],
    queryFn: () => {
      const value = toValue(id)
      invariant(value)
      return statusApi.fetchById(value).then((response) => response.data)
    },
  }),
})
