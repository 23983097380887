import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/data-transfer/DataTransferList.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'list',
        name: () => Translator.trans('u2_core.data_transfer.data_transfers'),
      },
    },
    name: 'DataTransferList',
    path: '/data-transfer',
  },
  {
    component: () => import('@js/pages/data-transfer/DataTransferStart.vue'),
    meta: {
      layout: BaseLayout,
    },
    name: 'DataTransferStart',
    path: '/data-transfer/:shortName/start',
    props: (route: RouteLocation) => ({ shortName: route.params.shortName }),
  },
  {
    component: () => import('@js/pages/data-transfer/DataTransferResult.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) =>
        Translator.trans('u2_core.data_transfer_result') + '#' + params.id,
    },
    name: 'DataTransferResult',
    path: '/data-transfer/:id/result',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
] as Array<RouteRecordRaw>
