import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/calendar/CalendarOverview.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'calendar',
        name: () => Translator.trans('u2_core.calendar'),
      },
    },
    name: 'CalendarOverview',
    path: '/calendar/week',
  },
] as Array<RouteRecordRaw>
