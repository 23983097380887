import { useQueryClient } from '@tanstack/vue-query'
import axios from 'axios'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import { queries } from '@js/query'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'
import type { Unit } from '@js/model/unit'

export default [
  {
    component: () => import('@js/pages/unit/UnitList.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
      globalSearch: {
        icon: 'unit',
        name: () => Translator.trans('u2.unit.plural'),
      },
    },
    name: 'UnitList',
    path: '/unit',
  },
  {
    component: () => import('@js/pages/unit/UnitEdit.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) => Translator.trans('u2.unit') + ` #${params.id}`,
    },
    name: 'UnitEdit',
    path: '/unit/:id/edit',
    props: (to: RouteLocation) => ({ unit: to.meta.unit }),
    beforeEnter: async (to: RouteLocation) => {
      const queryClient = useQueryClient()
      await Promise.all([
        axios.get<Unit>(`/api/units/${to.params.id}`).then(async ({ data }) => {
          to.meta.unit = data
        }),
        queryClient.fetchQuery(queries.unitProperties.all),
      ])
    },
  },
  {
    component: () => import('@js/pages/unit/UnitNew.vue'),
    meta: {
      auth: 'UNIT:MANAGE',
      layout: BaseLayout,
    },
    name: 'UnitNew',
    path: '/unit/new',
  },
] as Array<RouteRecordRaw>
