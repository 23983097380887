<template>
  <ButtonBase
    :disabled="disabled"
    :to="to ? to : href"
    :tooltip="tooltip"
    :type="type"
    :class="[
      'button inline-flex items-center justify-center gap-1 border px-2 py-1.5 no-underline transition-colors duration-300 ease-in-out',
      { 'disabled cursor-not-allowed': isDisabled },
      { rounded: !grouped },
      { 'grouped-button mx-0 first:rounded-l last:rounded-r': grouped },
      /**
       * TODO: Use an id instead of a class to identify vue buttons in behat
       * Classes are not unique. Therefore, multiple matches could be found by behat.
       */
      buttonStyle === 'solid' ? `colored-button-${color} border-transparent shadow` : '',
      buttonStyle === 'outlined' ? `outlined-button-${color} shadow` : '',
      buttonStyle === 'text' ? `text-button-${color} border-transparent` : '',
    ]"
    @click="emit('click', $event)"
  >
    <slot name="icon">
      <SvgIcon v-if="icon" :icon="icon" />
    </slot>

    <slot name="default" />
  </ButtonBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ButtonBase from '@js/components/buttons/ButtonBase.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import type { Color, Icon } from '@js/utilities/name-lists'

const props = withDefaults(
  defineProps<{
    buttonStyle?: 'outlined' | 'solid' | 'text'
    color?: Color
    disabled?: boolean
    grouped?: boolean
    icon?: Icon
    href?: string
    to?: string | object
    tooltip?: string
    type?: 'button' | 'reset' | 'submit'
  }>(),
  {
    buttonStyle: 'text',
    color: 'action',
    disabled: false,
    grouped: false,
    icon: undefined,
    href: undefined,
    to: undefined,
    tooltip: undefined,
    type: 'button',
  }
)

defineSlots<{
  default?: (props: Record<string, never>) => unknown
  icon?: (props: Record<string, never>) => unknown
}>()

const emit = defineEmits<(event: 'click', payload: Event) => void>()

const isDisabled = computed(() => props.disabled)
</script>

<style scoped>
@layer app-components {
  .button {
    @apply bg-transparent leading-4 text-action hover:bg-action-transparent hover:text-action-darker hover:no-underline focus:bg-action-transparent focus:text-action-darker focus:no-underline active:bg-blue-100;
  }

  .button.disabled,
  .button[disabled] {
    @apply cursor-not-allowed bg-transparent text-gray-500;
  }

  .button.disabled[class*='colored-button-'],
  .button[disabled][class*='colored-button-'],
  .button.disabled.grouped-button,
  .button[disabled].grouped-button {
    @apply bg-gray-200;
  }

  .button.disabled[class*='outlined-button-'],
  .button[disabled][class*='outlined-button-'] {
    @apply border-gray-300;
  }

  .grouped-button:not(
      :only-child,
      [class*='colored-button-'],
      [disabled],
      .disabled,
      :hover,
      :focus,
      :active
    ) {
    @apply bg-blue-100;
  }

  .grouped-button:not(
      :only-child,
      [class*='colored-button-'],
      [disabled],
      .disabled,
      :first-child
    ) {
    border-left-color: theme('colors.blue.200');
  }

  [class*='colored-button-'] {
    color: theme('textColor.white');
  }

  [class*='colored-button-']:not(
      [disabled],
      .disabled,
      .colored-button-white,
      .colored-button-light-blue
    ):hover,
  [class*='colored-button-']:not(
      [disabled],
      .disabled,
      .colored-button-white,
      .colored-button-light-blue
    ):focus {
    color: theme('textColor.white');
  }

  .colored-button-action {
    @apply bg-action hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-500;
  }

  .colored-button-action.grouped-button:not(:first-child) {
    border-left-color: theme('colors.blue.300');
  }

  .colored-button-good {
    @apply bg-good hover:bg-good-darker focus:bg-good-darker active:bg-good-lighter;
  }

  .colored-button-good.grouped-button:not(:first-child) {
    border-left-color: theme('colors.green.300');
  }

  .colored-button-invert {
    @apply bg-gray-700 hover:bg-gray-800 focus:bg-gray-800 active:bg-gray-700;
  }

  .colored-button-alert {
    @apply bg-alert hover:bg-alert-darker focus:bg-alert-darker;
  }

  .colored-button-bad {
    @apply bg-bad hover:bg-bad-darker focus:bg-bad-darker;
  }

  .colored-button-black {
    @apply bg-off-black hover:bg-gray-800 focus:bg-gray-800;
  }

  .colored-button-white {
    @apply bg-white text-off-black hover:bg-gray-100 hover:text-off-black focus:bg-gray-100 focus:text-off-black;
  }

  .colored-button-invert.grouped-button:not(:first-child) {
    border-left-color: theme('colors.gray.500');
  }

  .text-button-action {
    @apply text-action;
  }

  .text-button-good {
    @apply text-good;
  }

  .text-button-invert {
    @apply text-gray-700;
  }

  .text-button-alert {
    @apply text-alert;
  }

  .text-button-bad {
    @apply text-bad;
  }

  .text-button-black {
    @apply text-off-black;
  }

  .text-button-white {
    @apply text-white;
  }

  .outlined-button-action {
    @apply rounded border border-action text-action hover:rounded hover:border hover:border-blue-700 hover:text-blue-700 focus:rounded focus:border focus:border-blue-700 focus:text-blue-700;
  }

  .outlined-button-alert {
    @apply rounded border border-alert text-alert hover:rounded hover:border hover:border-alert-darker hover:bg-alert-transparent hover:text-alert-darker focus:rounded focus:border focus:border-alert-darker focus:bg-alert-transparent focus:text-alert-darker;
  }

  .outlined-button-bad {
    @apply rounded border border-bad text-bad hover:rounded hover:border hover:border-bad-darker hover:bg-bad-transparent hover:text-bad-darker focus:rounded focus:border focus:border-bad-darker focus:bg-bad-transparent focus:text-bad-darker;
  }

  .outlined-button-good {
    @apply rounded border border-good text-good hover:rounded hover:border hover:border-good-lighter hover:text-good-lighter focus:rounded focus:border focus:border-good-lighter focus:text-good-lighter;
  }

  .outlined-button-invert {
    @apply rounded border border-gray-700 text-gray-700 hover:rounded hover:border hover:bg-transparent-black hover:text-gray-800 focus:rounded focus:border focus:bg-transparent-black focus:text-gray-800;
  }

  .outlined-button-black {
    @apply rounded border border-off-black text-off-black hover:rounded hover:border hover:border-gray-800 hover:bg-transparent-black hover:text-gray-800 focus:rounded focus:border focus:border-gray-800 focus:bg-transparent-black focus:text-gray-800;
  }

  .outlined-button-white {
    @apply rounded border border-white text-white hover:rounded hover:border hover:border-gray-100 hover:bg-transparent-black hover:text-gray-100 focus:rounded focus:border focus:border-gray-100 focus:bg-transparent-black focus:text-gray-100;
  }

  .outlined-button-light-blue {
    @apply rounded border border-blue-100 text-blue-100 hover:border hover:border-action hover:bg-action-transparent focus:border focus:border-action focus:bg-action-transparent;
  }

  .button.text-alert:hover,
  .button.text-alert:focus {
    @apply bg-alert-transparent text-alert-darker;
  }

  .button.text-bad:hover,
  .button.text-bad:focus {
    @apply bg-bad-transparent text-bad-darker;
  }

  .button.text-good:hover,
  .button.text-good:focus {
    @apply bg-good-transparent text-good-darker;
  }

  .button.text-black:hover,
  .button.text-black:focus,
  .button.text-invert:hover,
  .button.text-invert:focus,
  .button.text-white:hover,
  .button.text-white:focus {
    @apply bg-transparent-black;
  }

  .text-invert {
    @apply text-gray-700;
  }

  .colored-button-light-blue {
    @apply bg-blue-100 text-action hover:bg-action-transparent hover:text-action focus:bg-action-transparent focus:text-action;
  }
}
</style>
