import axios from 'axios'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { Country } from '@js/model/country'

const basePath = '/api/countries'

function fetchAll() {
  return axios.get<HydraCollectionResponse<Country>>(basePath, {
    params: { pagination: false },
  })
}

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<Country>(iri)
}

function deleteById(id: number) {
  return axios.delete<Country>(`${basePath}/${id}`)
}

export const countryApi = {
  basePath,
  fetchAll,
  fetchById,
  fetchByIri,
  delete: deleteById,
}
