import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@js/query'

/**
 * Returns a TansStack query with all saved filters.
 */
export default function useSavedFilterAllQuery() {
  const query = useQuery(queries.savedFilters.all)

  return {
    ...query,
    items: computed(() => query.data.value?.data['hydra:member'] ?? []),
  }
}
