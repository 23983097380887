import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { Period } from '@js/model/period'

const basePath = '/api/periods'

export const periodApi = {
  basePath,
  fetchAll: function () {
    return axios.get<HydraCollectionResponse<Period>>(basePath, {
      params: { pagination: false },
    })
  },
  fetchById: function (id: number) {
    return axios.get<Period>(`${basePath}/${id}`)
  },
  delete: function (id: number) {
    return axios.delete<Period>(`${basePath}/${id}`)
  },
}
