import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { statusApi } from '@js/api/statusApi'
import type { ApiResourceId } from '@js/types'
import type { Status } from '@js/model/status'

export const useStatusStore = defineStore('status', () => {
  const statuses = ref<Array<Status>>([])
  const status = ref<Status>()

  async function fetchStatuses() {
    const { data } = await statusApi.fetchAll()
    statuses.value = data['hydra:member']
  }

  async function fetchStatusByIri(iri: ApiResourceId) {
    const { data } = await statusApi.fetchByIri(iri)
    status.value = data
  }

  return {
    statuses,
    status,
    fetchStatuses,
    fetchStatusByIri,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatusStore, import.meta.hot))
}
