import axios from 'axios'
import type { Attachment, UnrestrictedAttachment } from '@js/model/attachment'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'

const basePath = '/attachments'
function fetchAll(iri: ApiResourceId) {
  return axios.get<HydraCollectionResponse<Attachment>>(iri + basePath)
}

function download(attachment: UnrestrictedAttachment) {
  return axios.get<Blob>(attachment.links.downloadPath, { responseType: 'blob' })
}

export const attachmentApi = {
  fetchAll,
  download,
}
