import $ from 'jquery'

const ScrollTo = (() => {
  const defaultScrollSpeed = 300

  /**
   * Scroll the page to the given #target
   */
  const scrollTo = (target: string, scrollSpeed?: number | undefined) => {
    const $target = $(target)

    if (!$target.length) {
      // The target does not exist, do not try to scroll the page
      return
    }

    if (typeof scrollSpeed === 'undefined') {
      scrollSpeed = defaultScrollSpeed
    }

    const fixedElement = document.getElementById('page-header')
    const fixedElementsHeight = fixedElement === null ? 0 : fixedElement.offsetHeight
    const offset = $target.offset()
    if (offset === undefined) {
      throw new Error()
    }

    const scrollTop = offset.top - (fixedElementsHeight + 10) // 10px margin so it doesn't scroll too close to the header

    $('html, body').animate({ scrollTop }, scrollSpeed)
  }

  return {
    defaultScrollSpeed,
    scrollTo,
  }
})()

export default ScrollTo
