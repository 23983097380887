import { acceptHMRUpdate, defineStore } from 'pinia'
import findIndex from 'lodash/findIndex'
import { ref } from 'vue'

export const NotificationTypes = {
  success: 'success',
  notice: 'notice',
  warning: 'warning',
  error: 'error',
} as const

type NotificationType = (typeof NotificationTypes)[keyof typeof NotificationTypes]

export interface Notification {
  type: NotificationType
  message: string
}

export type MessageBag = Partial<Record<NotificationType, Array<string>>>

let updatedAt: number

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<Array<Notification>>([])

  function add(notification: Notification) {
    const now = Date.now()

    if (now > (updatedAt ?? 0) + 1500) {
      clear()
    }

    if (notification.type === 'success') {
      setTimeout(() => {
        if (notifications.value.filter((n) => n.type !== 'success').length === 0) {
          remove(notification)
        }
      }, 2500)
    }

    if (
      notifications.value.some(
        (existingNotification) => existingNotification.message === notification.message
      )
    ) {
      return
    }
    notifications.value.push(notification)
    updatedAt = now
  }
  function addByType(messagesByType: MessageBag) {
    Object.entries(messagesByType).forEach(([type, messages]) => {
      messages.forEach((message: string) => {
        add({ message, type: type as NotificationType })
      })
    })
  }
  function addSuccess(message: string) {
    add({ type: 'success', message })
  }
  function addNotice(message: string) {
    add({ type: 'notice', message })
  }
  function addWarning(message: string) {
    add({ type: 'warning', message })
  }
  function addError(message: string) {
    add({ type: 'error', message })
  }
  function clear() {
    notifications.value.splice(0, notifications.value.length)
  }
  function remove(notification: Notification) {
    const notificationIndex = findIndex(notifications.value, notification)
    if (notificationIndex !== -1) {
      notifications.value.splice(notificationIndex, 1)
    }
  }

  return {
    notifications,
    add,
    addByType,
    addSuccess,
    addNotice,
    addWarning,
    addError,
    clear,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationsStore, import.meta.hot))
}
