import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/auditor/AuditorList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        name: () => Translator.trans('u2_core.auditor.plural'),
        icon: 'config',
      },
    },
    name: 'AuditorList',
    path: '/configuration/auditor',
  },
  {
    component: () => import('@js/pages/auditor/AuditorEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => `${Translator.trans('u2_core.auditor')} #${params.id}`,
    },
    name: 'AuditorEdit',
    path: '/configuration/auditor/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/auditor/AuditorNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'AuditorNew',
    path: '/configuration/auditor/new',
  },
] as Array<RouteRecordRaw>
