import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/task/saved-filter/SavedFilterList.vue'),
    meta: {
      layout: BaseLayout,
      layoutFormat: 'wide',
      globalSearch: {
        icon: 'list',
        name: () => Translator.trans('u2.saved_filters'),
      },
    },
    name: 'SavedFilterList',
    path: '/filter/list',
  },
  {
    component: () => import('@js/pages/task/saved-filter/SavedFilterEdit.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) => Translator.trans('u2.saved_filter') + ' #' + params.id,
    },
    name: 'SavedFilterEdit',
    path: '/filter/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/task/saved-filter/SavedFilterSubscriptionEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: BaseLayout,
      title: (params: { id: number }) =>
        Translator.trans('u2.saved_filter_subscription') + ' #' + params.id,
    },
    name: 'SavedFilterSubscriptionEdit',
    path: '/saved-filter-subscriptions/saved-filter/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/task/saved-filter/SavedFilterSubscriptionNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: BaseLayout,
    },
    name: 'SavedFilterSubscriptionNew',
    path: '/saved-filter-subscriptions/saved-filter/:filterId/new',
    props: (route: RouteLocation) => ({ filterId: +route.params.filterId }),
  },
  {
    component: () => import('@js/pages/task/saved-filter/SavedFilterSubscriptionList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.saved_filter_subscriptions'),
      },
    },
    name: 'SavedFilterSubscriptionList',
    path: '/saved-filter-subscriptions/saved-filter',
  },
] as Array<RouteRecordRaw>
