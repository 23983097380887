import Translator from 'bazinga-translator'
import config from '@js/../../api/shared/translation/config.json'
import englishMessages from '@js/../../api/shared/translation/en.json'
import germanMessages from '@js/../../api/shared/translation/de.json'
import { useUserSettingsStore } from '@js/stores/user-settings'

type TranslationConfig = {
  translations: {
    en: { validators: Record<string, string>; messages: Record<string, string> }
    de: { validators: Record<string, string>; messages: Record<string, string> }
  }
  fallback: string
  defaultDomain: string
}

const buildTranslationConfig = () => {
  Translator.reset()

  const translatorConfiguration = {
    translations: {},
    ...config,
  } as TranslationConfig
  translatorConfiguration.translations.en = englishMessages.translations.en
  translatorConfiguration.translations.de = germanMessages.translations.de

  return JSON.stringify(translatorConfiguration)
}

Translator.fromJSON(buildTranslationConfig())

export type TranslationId =
  | keyof typeof englishMessages.translations.en.validators
  | keyof typeof englishMessages.translations.en.messages

const trans = (
  id: TranslationId,
  parameters?: Record<string, string | number | undefined>,
  domain?: string | undefined,
  locale?: string | undefined
) => {
  return Translator.trans(id, parameters, domain, locale ?? useUserSettingsStore().locale)
}
const transChoice = (
  id: string,
  number: number,
  parameters?: Record<string, string | number | undefined>,
  domain?: string,
  locale?: string
) => {
  return Translator.transChoice(
    id,
    number,
    parameters,
    domain,
    locale ?? useUserSettingsStore().locale
  )
}
export default {
  ...Translator,
  trans,
  transChoice,
}
