import axios from 'axios'
import type { Field, Layout, LayoutItem } from '@js/model/layout'
import type { HydraCollectionResponse } from '@js/types'

export const basePath = '/api/layouts/{layoutId}/fields'
const buildBasePath = (layoutId: Layout['id']) =>
  basePath.replace('{layoutId}', layoutId.toString())

function fetchByIri(iri: Field['@id']) {
  return axios.get<Field>(iri)
}

function fetchById(id: Field['id'], layoutId: Layout['id']) {
  return fetchByIri(`${buildBasePath(layoutId)}/${id}`)
}

function fetchAll(layoutId: Layout['id']) {
  return axios.get<HydraCollectionResponse<Field>>(buildBasePath(layoutId), {
    params: { pagination: false },
  })
}

function save(
  field: Partial<Omit<Field, 'item'>> & { item?: LayoutItem['@id'] },
  layoutId: Layout['id']
) {
  if (field.id === undefined) {
    return axios.post<Field>(`${buildBasePath(layoutId)}`, field)
  }
  return axios.put<Field>(`${buildBasePath(layoutId)}/${field.id}`, field)
}

export const layoutFieldApi = {
  fetchByIri,
  fetchById,
  fetchAll,
  save,
  buildBasePath,
  delete: (fieldId: Field['id'], layoutId: Layout['id']) =>
    axios.delete<Field>(`${buildBasePath(layoutId)}/${fieldId}`),
}
