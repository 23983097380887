import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { UnitProperty } from '@js/model/unit'

const basePath = '/api/unit-properties'

export const unitPropertyApi = {
  all: function () {
    return axios.get<HydraCollectionResponse<UnitProperty>>(basePath)
  },
}
