const Cookie = (() => {
  /**
   * Create (or update if it already exists) a cookie
   * If no expiration days are defined the cookie will be trashed after the user closes the browser
   *
   * @param name
   * @param value
   * @param expirationInDays
   */
  const writeCookie = (
    name: string,
    value: string | boolean | number,
    expirationInDays?: number
  ) => {
    let expires = ''
    // Set the expiry date
    if (expirationInDays) {
      const expirationDate = new Date()
      const expirationInMilliseconds = expirationInDays * 24 * 60 * 60 * 1000
      expirationDate.setTime(expirationDate.getTime() + expirationInMilliseconds)
      // Define the expires option
      expires = '; expires=' + expirationDate.toUTCString()
    }
    // Write the cookie
    document.cookie = name + '=' + value + expires + '; SameSite=Strict; path=/'
  }

  /**
   * Read a cookies value
   */
  const readCookie = (name: string) => {
    const nameWithEqual = name + '='
    // Get an array of all the existent cookies by splitting the document.cookie string
    const cookieArray = document.cookie.split(';')
    // Go through the array of cookies
    for (const cookieContent of cookieArray) {
      // Trim white space at the beginning and the end of the cookie string
      const cookie = cookieContent.trim()
      // If this is the cookie we’re looking for
      if (cookie.indexOf(nameWithEqual) === 0) {
        // Get the value of the cookie by removing the name and the equal from the beginning of the cookie string
        return cookie.substring(nameWithEqual.length, cookie.length)
      }
    }
    // The cookie was not found
    return null
  }

  /**
   * Erase a cookie by giving it an expiry date of one day ago (this makes the browser trash the cookie immediately)
   *
   * @param name
   */
  const eraseCookie = (name: string) => {
    writeCookie(name, '', -1)
  }

  /**
   * Exposed (public) functions
   */
  return {
    erase: eraseCookie,
    read: readCookie,
    write: writeCookie,
  }
})()

export default Cookie
