<template>
  <TransitionRoot appear :show="isShowing" as="template">
    <Dialog :initial-focus="closeButtonRef" class="relative z-40">
      <!-- OVERLAY -->
      <TransitionChild
        as="div"
        class="fixed inset-0 bg-black/30"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        aria-hidden="true"
      />

      <TransitionChild
        as="div"
        class="fixed inset-0"
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
      >
        <!-- MODAL -->
        <DialogPanel
          v-bind="$attrs"
          class="app-dialog fixed left-1/2 top-[10vh] mx-auto my-0 flex max-h-3/4 flex-auto -translate-x-1/2 transform flex-col rounded bg-white p-0 leading-loose shadow"
        >
          <!-- HEADER -->
          <DialogTitle
            as="div"
            class="flex h-9 shrink-0 items-center justify-between rounded rounded-b-none bg-gray-950 bg-gradient-to-b from-gray-700 px-3 py-0"
          >
            <span
              class="truncate pr-6 text-base font-bold uppercase tracking-wider text-white hover:no-underline focus:no-underline"
            >
              {{ title }}
            </span>

            <button
              ref="closeButtonRef"
              type="button"
              class="test-app-dialog-title-close-button flex items-center"
              @click="close"
            >
              <SvgIcon icon="cross" class="text-white" />
            </button>
          </DialogTitle>

          <!-- LOADER -->
          <div v-if="loading" class="flex h-32 items-center justify-center">
            <AppLoader class="flex-grow" size="large" />
          </div>

          <!-- BODY -->
          <div v-else class="h-full overflow-scroll p-3">
            <slot />
          </div>

          <!-- FOOTER -->
          <div
            v-if="!!$slots.buttons"
            class="flex h-12 items-center justify-end gap-1 rounded rounded-t-none border-t border-gray-300 bg-gray-100 p-2"
          >
            <slot v-if="!loading" name="buttons" />
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { onMounted, ref } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import AppLoader from '@js/components/loader/AppLoader.vue'
import SvgIcon from '@js/components/SvgIcon.vue'

defineOptions({ inheritAttrs: false })

withDefaults(
  defineProps<{
    loading?: boolean
    title?: string
  }>(),
  {
    loading: false,
    title: '',
  }
)

const isShowing = ref(false)
onMounted(() => {
  isShowing.value = true
})

const closeButtonRef = ref(null)

const emit = defineEmits<(event: 'close') => void>()

function close() {
  emit('close')
}

// TODO: Add a save prompt and use default behaviour for closing dialog on click outside
// https://universalunits.atlassian.net/browse/UU-6291
// For now we prevent the dialog from closing on click outside, but we allow it to close if Escape is pressed.
// As soon as save prompt is implemented and used in the dialog we can allow closing on click outside and remove the
// code below
onKeyStroke('Escape', () => {
  close()
})
</script>
