import { useElementSize, useWindowSize } from '@vueuse/core'
import { computed } from 'vue'

const { height: windowHeight } = useWindowSize()
const { height: bodyHeight } = useElementSize(document.body)

export function useHasWindowVerticalScrollbar() {
  const hasVerticalScrollbar = computed(() => {
    return bodyHeight.value > windowHeight.value
  })
  return { hasVerticalScrollbar }
}
