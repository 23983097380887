import axios from 'axios'
import type { FileType } from '@js/model/fileType'
import type { HydraCollectionResponse } from '@js/types'

const basePath = '/api/configuration/file-types'

function fetchById(id: FileType['id']) {
  return axios.get<FileType>(`${basePath}/${id}`)
}
function fetchAll() {
  return axios.get<HydraCollectionResponse<FileType>>(basePath, {
    params: { sort: { name: 'ASC' }, pagination: false },
  })
}

export const fileTypeApi = {
  fetchById,
  fetchAll,
}
