import BaseLayout from '@js/layouts/BaseLayout.vue'
import AppError from '@js/pages/error/AppError.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/error/AppError404.vue'),
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'Error404',
    path: '/:pathMatch(.*)*',
  },
  {
    component: () => import('@js/pages/error/AppError403.vue'),
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'Error403',
    path: '/:pathMatch(.*)*',
  },
  {
    component: () => import('@js/pages/error/AppError503.vue'),
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'Error503',
    path: '/:pathMatch(.*)*',
  },
  {
    component: AppError,
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'Error',
    path: '/:pathMatch(.*)*',
  },
] as Array<RouteRecordRaw>
