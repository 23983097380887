import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { fileApi } from '@js/api/fileApi'

export const files = createQueryKeys('files', {
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      fileApi.fetchByQuery({
        ...unref(filters),
      }),
  }),
})
