import type { ApiResource, ApiResourceId } from '@js/types'
import type { DocumentTemplateSection } from '@js/api/documentTemplateApi'

export const newSectionTitleIdentifier = '***new-section***'

export type Document = ApiResource & {
  id: number
  sections: Array<ApiResourceId>
}

export type BaseDocumentSection = ApiResource & {
  document: NonNullable<Document['@id']>
  displayName: string
  id: number
  name: string
  content: string
  level: number
  createdBy?: ApiResourceId
  updatedBy?: ApiResourceId
  updatedAt?: string
  createdAt?: string
  include: boolean
  required: boolean
  editable: boolean
}

type MasterFileSection = BaseDocumentSection & {
  '@type': 'MasterFileSection'
}

type LocalFileSection = BaseDocumentSection & {
  '@type': 'LocalFileSection'
}

type CountryByCountryReportSection = BaseDocumentSection & {
  '@type': 'CountryByCountryReportSection'
}

export type DocumentSection =
  | MasterFileSection
  | LocalFileSection
  | CountryByCountryReportSection
  | DocumentTemplateSection

export type DocumentWidget = {
  name: string
  isConfigurable: boolean
  parameters?: Record<string, unknown>
}

export type DocumentImageWidget = Omit<DocumentWidget, 'name'> & {
  name: 'image'
  parameters: { id: string; width: string }
}

export type DocumentFileWidget = Omit<DocumentWidget, 'name'> & {
  name: 'file'
  parameters: {
    id: string
    text: string
  }
}

export type BusinessActivitiesWidget = Omit<DocumentWidget, 'name'> & {
  name: 'business-activities'
}

export type TransactionTableWidget = Omit<DocumentWidget, 'name'> & {
  name: 'transaction-table'
  parameters: {
    group_results: boolean
    transaction_type: string
    output_currency_iso_code: string
    sub_filter: string
    show_UnitId: boolean
    show_UnitName: boolean
    show_UnitCountry: boolean
    show_PartnerUnitId: boolean
    show_PartnerUnitName: boolean
    show_PartnerUnitCountry: boolean
    show_Type: boolean
    show_SubType: boolean
    show_TransferPricingMethod: boolean
    show_ContractExpiryDate: boolean
    show_CouponInterestRate: boolean
    show_CouponInterestRateType: boolean
    show_PreviousPeriodBookValue: boolean
    show_CurrentPeriodInterestExpenses: boolean
    show_CurrentPeriodBookValue: boolean
    show_MaturityDate: boolean
    show_GuaranteeFeeAmount: boolean
    show_AssetLiabilityId: boolean
    show_ForwardRate: boolean
    show_ContractDate: boolean
    show_CurrencyConvertedSum: boolean
  }
}

export type TransactionUnitsWidget = Omit<DocumentWidget, 'name'> & {
  name: 'transaction-units'
  parameters: {
    style: string
    show_ref_id: boolean
    show_name: boolean
    show_currency: boolean
    show_country: boolean
    show_country_founded: boolean
    show_legal_name: boolean
    show_legal_form: boolean
    show_tax_number: boolean
    show_register_number: boolean
    show_registry_place: boolean
    show_postal_address: boolean
  }
}

export type PageBreakWidget = Omit<DocumentWidget, 'name'> & {
  name: 'page-break'
}
export type AssociatedUnitsWidget = Omit<DocumentWidget, 'name'> & {
  name: 'associated-units'
  parameters: {
    style: string
    show_ref_id: true
    show_name: true
    show_currency: true
    show_country: true
    show_country_founded: true
    show_legal_name: false
    show_legal_form: false
    show_tax_number: false
    show_register_number: false
    show_registry_place: false
    show_postal_address: false
  }
}

export type FinancialDataPerCountryTable = Omit<DocumentWidget, 'name'> & {
  name: 'financial-data-per-country-table'
}

export type FinancialDataSummaryTable = Omit<DocumentWidget, 'name'> & {
  name: 'financial-data-summary-table'
}

export type MainContractsWidget = Omit<DocumentWidget, 'name'> & {
  name: 'main-contracts'
  parameters: {
    show_unit_country: boolean
    show_unit_ref_id: boolean
    show_contract_name: boolean
    show_contract_type: boolean
    show_date: boolean
    show_partner_unit_ref_id: boolean
    show_partner_unit_country: boolean
  }
}

export type AnyDocumentWidget =
  | BusinessActivitiesWidget
  | TransactionTableWidget
  | PageBreakWidget
  | DocumentImageWidget
  | DocumentFileWidget
  | MainContractsWidget
  | AssociatedUnitsWidget
  | TransactionUnitsWidget
  | FinancialDataPerCountryTable
  | FinancialDataSummaryTable

// TODO: Fetch the list of widgets from a configuration endpoint to have a single source of truth
export const configurableWidgets: Array<AnyDocumentWidget['name']> = [
  'associated-units',
  'file',
  'image',
  'main-contracts',
  'transaction-table',
  'transaction-units',
]

export const documentWidgets: Array<AnyDocumentWidget['name']> = [
  'associated-units',
  'business-activities',
  'file',
  'financial-data-per-country-table',
  'financial-data-summary-table',
  'image',
  'main-contracts',
  'page-break',
  'transaction-table',
  'transaction-units',
]

export const documentSectionTypeToShortName = {
  CountryByCountryReportSection: 'tpm-country-by-country-report-section',
  DocumentTemplateSection: 'document-template-section',
  LocalFileSection: 'tpm-local-file-section',
  MasterFileSection: 'tpm-master-file-section',
} as const

export function isDocumentSectionType(
  type: string
): type is keyof typeof documentSectionTypeToShortName {
  return Object.keys(documentSectionTypeToShortName).includes(type)
}

export function isMasterFileSection(entity: ApiResource): entity is MasterFileSection {
  return entity['@type'] === 'MasterFileSection'
}

export function isLocalFileSection(entity: ApiResource): entity is LocalFileSection {
  return entity['@type'] === 'LocalFileSection'
}

export function isCountryByCountryReportSection(
  entity: ApiResource
): entity is CountryByCountryReportSection {
  return entity['@type'] === 'CountryByCountryReportSection'
}

export function isDocumentTemplateSection(entity: ApiResource): entity is DocumentTemplateSection {
  return entity['@type'] === 'DocumentTemplateSection'
}
