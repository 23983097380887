import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/system-message/SystemMessageEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.system_message')} #${params.id}`
      },
    },
    name: 'SystemMessageEdit',
    path: '/system-message/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/system-message/SystemMessageNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'SystemMessageNew',
    path: '/system-message/new',
  },
  {
    component: () => import('@js/pages/system-message/SystemMessageList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.system_message.plural'),
      },
    },
    name: 'SystemMessageList',
    path: '/system-message',
  },
] as Array<RouteRecordRaw>
