import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import { documentTemplateApi } from '@js/api/documentTemplateApi'
import type { MaybeRefOrGetter } from 'vue'
import type { DocumentTemplate } from '@js/model/document-template'

export const documentTemplates = createQueryKeys('documentTemplates', {
  single: (id: MaybeRefOrGetter<DocumentTemplate['id']>) => ({
    queryKey: [id],
    queryFn: () => documentTemplateApi.fetchById(toValue(id)).then((response) => response.data),
    contextQueries: {
      sections: {
        queryKey: [id],
        queryFn: () =>
          documentTemplateApi.fetchSections(toValue(id)).then((response) => response.data),
      },
      renderedSections: {
        queryKey: [id],
        queryFn: () =>
          documentTemplateApi.fetchRenderedSections(toValue(id)).then((response) => response.data),
      },
    },
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      documentTemplateApi.fetchByQuery(toValue(params)).then((response) => response.data),
  }),
})
