import axios from 'axios'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { HydraCollectionResponse } from '@js/types'
import type { FileAccessType, FileEntity } from '@js/model/file'

const basePath = '/api/files'

export type FileCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[id]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  accessType?: FileAccessType
  search?: string | Array<string>
}

function fetchById(id: FileEntity['id']) {
  return axios.get<FileEntity>(`${basePath}/${id}`)
}

function fetchByQuery(query?: FileCollectionQuery) {
  return axios.get<HydraCollectionResponse<FileEntity>>(basePath, {
    params: { ...query },
  })
}

function sendPermissionsRequest(fileId: number, comment: string) {
  return axios.post(`${basePath}/${fileId}/request-permissions`, { comment })
}

export const fileApi = {
  fetchById,
  sendPermissionsRequest,
  fetchByQuery,
}
