import { createQueryKeys } from '@lukemorales/query-key-factory'
import { authorizationApi } from '@js/api/authorizationApi'

export const authorizations = createQueryKeys('authorizations', {
  all: null,
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => authorizationApi.fetchAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            authorizationApi.fetchInheritedAssignedUsers(id).then((response) => response.data),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            authorizationApi.fetchAssignedUserGroups(id).then((response) => response.data),
        },
      },
    }
  },
})
