import { StatusCodes } from 'http-status-codes'
import { fileApi } from '@js/api/fileApi'
import { createErrorLocation } from '@js/router/helpers'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/file/FileList.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'list',
        name: () => Translator.trans('u2_core.files'),
      },
      layoutFormat: 'wide',
    },
    name: 'FileList',
    path: '/file',
  },
  {
    component: () => import('@js/pages/file/FileNew.vue'),
    meta: {
      layout: BaseLayout,
    },
    name: 'FileNew',
    path: '/file/new',
  },
  {
    component: () => import('@js/pages/file/FileEdit.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2.file')}: #${params.id}`
      },
    },
    name: 'FileEdit',
    path: '/file/:id/edit',
    props: (to: RouteLocation) => ({ file: to.meta.file }),
    beforeEnter: async (to: RouteLocation) => {
      const { data: file } = await fileApi.fetchById(Number(to.params.id))
      if (file.name === null) {
        return createErrorLocation(to, StatusCodes.FORBIDDEN)
      }
      to.meta.file = file
    },
    beforeRouteUpdate: async (to: RouteLocation, from: RouteLocation) => {
      if (to.params.id !== from.params.id) {
        const { data: file } = await fileApi.fetchById(Number(to.params.id))
        if (file.name === null) {
          return createErrorLocation(to, StatusCodes.FORBIDDEN)
        }
        to.meta.file = file
      }
    },
  },
] as Array<RouteRecordRaw>
