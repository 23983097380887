import axios from 'axios'
import type { SystemSettings } from '@js/model/system_setting'

const basePath = '/api/system-settings'

export const systemSettingApi = {
  basePath,
  fetch: function () {
    return axios.get<SystemSettings>(basePath)
  },
  update: function (systemSettings: Omit<SystemSettings, '@id' | '@type'>) {
    return axios.put<SystemSettings>(basePath, {
      ...systemSettings,
    })
  },
}
