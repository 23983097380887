import axios from 'axios'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import Routing from '@js/Routing'

export const menu = createQueryKeys('menu', {
  mainMenuJson: {
    queryKey: null,
    queryFn: () => axios.get(Routing.generate('u2_menu_mainjson')),
  },
  userMenuJson: {
    queryKey: null,
    queryFn: () => axios.get(Routing.generate('u2_menu_userjson')),
  },
})
