import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { toValue } from 'vue'
import { workflowTransitionApi } from '@js/api/workflowTransitionApi'
import type { Transition } from '@js/api/workflowTransitionApi'
import type { MaybeRefOrGetter } from 'vue'

export const workflowTransitions = createQueryKeys('workflowTransitions', {
  single: (id: MaybeRefOrGetter<Transition['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = toValue(id)
        invariant(idValue)
        return workflowTransitionApi.fetchById(idValue).then((response) => response.data)
      },
    }
  },
})
