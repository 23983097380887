import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { currencyApi } from '@js/api/currencyApi'
import type { Currency } from '@js/model/currency'
import type { MaybeRef } from '@vueuse/core'

export const currencies = createQueryKeys('currencies', {
  all: {
    queryKey: null,
    queryFn: () => currencyApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<Currency['id']>) => ({
    queryKey: [id],
    queryFn: () => currencyApi.fetchById(unref(id)).then((response) => response.data),
  }),
  list: (params) => ({
    queryKey: [params],
    queryFn: () => currencyApi.fetchByQuery(unref(params)).then((response) => response.data),
  }),
})
