import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { systemMessageApi } from '@js/api/systemMessageApi'
import type { SystemMessage } from '@js/model/system_message'
import type { MaybeRef } from '@vueuse/core'

export const systemMessages = createQueryKeys('systemMessages', {
  active: {
    queryKey: null,
    queryFn: () => systemMessageApi.fetchActive().then((response) => response.data),
  },
  all: {
    queryKey: null,
    queryFn: () => systemMessageApi.fetchAll().then((response) => response.data),
  },
  list: (params) => ({
    queryKey: [params],
    queryFn: () => systemMessageApi.fetchByQuery(unref(params)).then((response) => response.data),
  }),
  single: (id: MaybeRef<SystemMessage['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => systemMessageApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
