import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { layoutApi } from '@js/api/layoutApi'
import { layoutFieldApi } from '@js/api/layoutFieldApi'
import type { MaybeRef } from '@vueuse/core'
import type { Field, Layout } from '@js/model/layout'

export const layouts = createQueryKeys('layouts', {
  all: { queryKey: null, queryFn: () => layoutApi.fetchAll().then((response) => response.data) },
  list: (filters) => ({
    queryKey: [filters],
    queryFn: () =>
      layoutApi.fetchByQuery({
        ...unref(filters),
      }),
  }),
  single: (id: MaybeRef<Layout['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => layoutApi.fetchById(unref(id)).then((response) => response.data),
      contextQueries: {
        fields: {
          queryKey: [id],
          queryFn: () => layoutFieldApi.fetchAll(unref(id)),
          contextQueries: {
            single: (fieldId: MaybeRef<Field['id']>) => {
              return {
                queryKey: [fieldId, id],
                queryFn: () =>
                  layoutFieldApi
                    .fetchById(unref(fieldId), unref(id))
                    .then((response) => response.data),
              }
            },
          },
        },
        layoutCollections: {
          queryKey: [id],
          queryFn: () =>
            layoutApi.fetchLayoutCollections(unref(id)).then((response) => response.data),
        },
      },
    }
  },
})
