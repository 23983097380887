import { createQueryKeys } from '@lukemorales/query-key-factory'
import { savedFilterSubscriptionApi } from '@js/api/savedFilterSubscriptionApi'

export const savedFilterSubscriptions = createQueryKeys('savedFilterSubscriptions', {
  all: null,
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            savedFilterSubscriptionApi.fetchAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            savedFilterSubscriptionApi
              .fetchInheritedAssignedUsers(id)
              .then((response) => response.data),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            savedFilterSubscriptionApi
              .fetchAssignedUserGroups(id)
              .then((response) => response.data),
        },
      },
    }
  },
})
