import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue, unref } from 'vue'
import invariant from 'tiny-invariant'
import { unitHierarchyApi } from '@js/api/unitHierarchyApi'
import type { MaybeRef } from '@vueuse/core'
import type { Unit } from '@js/model/unit'

export const unitHierarchies = createQueryKeys('unitHierarchies', {
  all: {
    queryKey: null,
    queryFn: () => unitHierarchyApi.fetchAll().then((response) => response.data),
  },
  list: (params) => ({
    queryKey: [params],
    queryFn: () => unitHierarchyApi.fetchByQuery(unref(params)).then((response) => response.data),
  }),
  single: (id: MaybeRef<Unit['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const value = toValue(id)
        invariant(value !== undefined, 'id is required')
        return unitHierarchyApi.fetchById(value).then((response) => response.data)
      },
      contextQueries: {
        structure: (date: MaybeRef<string>) => ({
          queryKey: [date],
          queryFn: () => {
            const value = toValue(id)
            invariant(value !== undefined, 'id is required')

            return unitHierarchyApi
              .fetchStructure(value, unref(date))
              .then((response) => response.data)
          },
        }),
      },
    }
  },
})
