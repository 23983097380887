<template>
  <section class="page-content flex-auto">
    <div class="flex h-full w-full items-center justify-center pb-40">
      <div class="flex w-11/12 max-w-xl items-center rounded border bg-white p-4 shadow-xl sm:p-10">
        <div class="sm:w-2/3">
          <h5>Universal Units</h5>
          <h1 class="sm:whitespace-nowrap">
            <slot name="title">{{ code }} {{ title }}</slot>
          </h1>
          <slot>
            <p>{{ getMessage() }}</p>
          </slot>
          <router-link v-if="hasLoginLink" :to="{ name: 'AppLogin' }">
            Return to the login page.
          </router-link>
        </div>
        <span class="hidden w-1/3 text-center sm:block">
          <SvgIcon size="manual" class="m-auto size-20 text-gray-300" :icon="icon" />
        </span>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import type { Icon } from '@js/utilities/name-lists'

const props = withDefaults(
  defineProps<{
    code?: string
    hasLoginLink?: boolean
    icon?: Icon
    title?: string
  }>(),
  {
    code: '500',
    hasLoginLink: false,
    icon: 'alert',
    title: () => Translator.trans('u2.http_500'),
  }
)

useHead({
  title: () => `${props.code} - ${props.title}`,
})

function getMessage() {
  switch (props.code) {
    case '400':
      return Translator.trans('u2.http_400')
    case '405':
      return Translator.trans('u2.http_405')
    case '408':
      return Translator.trans('u2.http_408')
    case '422':
      return Translator.trans('u2.http_422')
    case '500':
    default:
      return 'Please contact us with a description of how the error occurred and we will try to fix the problem as soon as possible.'
  }
}
</script>
