import axios from 'axios'
import { useAuthStore } from '@js/stores/auth'
import type { HydraCollectionResponse } from '@js/types'
import type { SavedFilter, SavedFilterSubscription } from '@js/model/saved-filter'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

const baseUrl = '/api/saved-filters'
function fetchByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'sort[tableViewConfigurationFullyQualifiedClass]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[owner.username]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[public]'?: SortingDirection
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<SavedFilter>>(baseUrl, {
    params: { ...query },
  })
}

function fetchAll() {
  return fetchByQuery({ pagination: false })
}

function fetchMyFavourites() {
  return axios.get<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters'
  )
}

function addToMyFavourites(savedFilter: SavedFilter) {
  return axios.post<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters',
    { savedFilter: savedFilter['@id'] }
  )
}
function removeFromMyFavourites(savedFilter: Pick<SavedFilter, 'id'>) {
  return axios.delete<HydraCollectionResponse<SavedFilter>>(
    useAuthStore().user?.['@id'] + '/favourite-saved-filters/' + savedFilter.id
  )
}

function fetchById(id: number) {
  return axios.get<SavedFilter>(`${baseUrl}/${id}`)
}

function fetchAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${baseUrl}/${id}/groups`, {
    params: { pagination: false },
  })
}

function updateAssignedUserGroups(id: number, updatedGroups: Array<NonNullable<UserGroup['@id']>>) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${baseUrl}/${id}/groups`, {
    groups: updatedGroups,
  })
}

function fetchAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${baseUrl}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${baseUrl}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

function fetchInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${baseUrl}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

function fetchSubscriptions(id: SavedFilter['id']) {
  return axios.get<HydraCollectionResponse<SavedFilterSubscription>>(
    `${baseUrl}/${id}/saved-filter-subscriptions`,
    {
      params: { pagination: false },
    }
  )
}

export const savedFilterApi = {
  fetchAll,
  fetchMyFavourites,
  fetchByQuery,
  fetchById,
  addToMyFavourites,
  removeFromMyFavourites,
  fetchAssignedUserGroups,
  fetchAssignedUsers,
  updateAssignedUserGroups,
  updateAssignedUsers,
  fetchInheritedAssignedUsers,
  fetchSubscriptions,
}
