import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { taskApi } from '@js/api/taskApi'
import type { PermissionMask } from '@js/model/permission'

export const tasks = createQueryKeys('tasks', {
  single: (id) => {
    return {
      queryKey: [id],
      queryFn: () => taskApi.fetchTaskById(unref(id)).then((response) => response.data),
      contextQueries: {
        layoutCollections: (permission?: PermissionMask) => {
          return {
            queryKey: [permission],
            queryFn: () =>
              taskApi
                .fetchLayoutCollections(unref(id), unref(permission))
                .then((response) => response.data),
          }
        },
      },
    }
  },
})
