import axios from 'axios'
import handleErrorResponse from '@js/axios/handleErrorResponse'
import { useAuthStore } from '@js/stores/auth'

const Axios = (() => {
  function setup() {
    // Set header for Symfony to recognise it is an ajax call
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

    // Set auth token for api requests
    axios.interceptors.request.use(async (request) => {
      const url = request.url
      if (
        url &&
        url.includes('/api/') &&
        !url.includes('/api/login') &&
        !url.includes('/api/app-status')
      ) {
        const authStore = useAuthStore()
        await authStore.ensureToken()
        if (authStore.token) {
          request.headers.Authorization = 'Bearer ' + authStore.token
        }
        if (request.method === 'patch') {
          request.headers['Content-Type'] = 'application/merge-patch+json'
        }
      }

      return request
    })

    // Handle errors
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && handleErrorResponse(error.response)) {
          return Promise.resolve()
        }

        return Promise.reject(error)
      }
    )
  }

  return {
    setup,
  }
})()

export default Axios
