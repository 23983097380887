import axios from 'axios'
import type { HydraCollectionResponse, NewApiResource } from '@js/types'
import type {
  UnitHierarchy,
  UnitHierarchyStructure,
  UnitHierarchyStructureApiFormat,
} from '@js/model/unit_hierarchy'

const basePath = '/api/unit-hierarchies'

function fetchById(id: UnitHierarchy['id']) {
  return axios.get<UnitHierarchy>(`${basePath}/${id}`)
}

function fetchByQuery(query: { page?: number; pagination?: boolean; itemsPerPage?: number }) {
  return axios.get<HydraCollectionResponse<UnitHierarchy>>(basePath, {
    params: { ...query },
  })
}
export const unitHierarchyApi = {
  basePath,
  fetchAll: function () {
    return axios.get<HydraCollectionResponse<UnitHierarchy>>(basePath, {
      params: { pagination: false },
    })
  },
  fetchById,
  fetchByQuery,
  create: function (payload: NewApiResource<UnitHierarchy>) {
    return axios.post<UnitHierarchy>(`${basePath}`, payload)
  },
  update: function (payload: UnitHierarchy) {
    return axios.put<UnitHierarchy>(payload['@id'], payload)
  },
  updateStructure: function (payload: UnitHierarchyStructureApiFormat) {
    return axios.put<UnitHierarchyStructure>(payload['@id'], payload)
  },
  fetchStructure: function (id: UnitHierarchy['id'], date: string) {
    return axios.get<UnitHierarchyStructure>(`${basePath}/${id}/structure/${date}`)
  },
}
