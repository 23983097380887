import { type NavigationHookAfter, createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { useSessionStorage } from '@vueuse/core'
import { type Component } from 'vue'
import { createErrorLocation } from '@js/router/helpers'
import Auditor from '@js/router/auditor'
import Authorization from '@js/router/authorization'
import Calendar from '@js/router/calendar'
import Choice from '@js/router/choice-list'
import Countries from '@js/router/country'
import Currency from '@js/router/currency'
import Dashboard from '@js/router/dashboard'
import DataTransfer from '@js/router/data-transfer'
import DocumentTemplate from '@js/router/document-template'
import Error from '@js/router/error'
import File from '@js/router/file'
import FileType from '@js/router/file-type'
import GeneralConfiguration from '@js/router/general-configuration'
import Import from '@js/router/import'
import Layout from '@js/router/layouts'
import LegalForm from '@js/router/legal-form'
import Period from '@js/router/period'
import SavedFilter from '@js/router/saved-filter'
import Security from '@js/router/security'
import Status from '@js/router/status'
import Support from '@js/router/support'
import SystemMessage from '@js/router/system-message'
import TaskType from '@js/router/task-type'
import Unit from '@js/router/unit'
import UnitHierarchy from '@js/router/unit_hierarchy'
import { useAuthStore } from '@js/stores/auth'
import User from '@js/router/user'
import UserGroup from '@js/router/user-group'
import Workflow from '@js/router/workflow'
import WorkflowFieldConfiguration from '@js/router/fieldConfiguration'
import LayoutCollections from '@js/router/layoutCollections'
import { useUserSettingsStore } from '@js/stores/user-settings'
import { useLoadingIndicatorStore } from '@js/stores/loading-indicator'
import type { AuthorizationString } from '@js/model/authorization'
import type { Role } from '@js/model/role'
import type { Icon } from '@js/utilities/name-lists'
import type { RouteLocationNormalized } from 'vue-router'

declare module 'vue-router' {
  interface RouteMeta {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props?: Record<string, any>
    layout?: Component
    layoutFormat?: 'default' | 'wide'
    auth?: boolean | Role | AuthorizationString
    globalSearch?: {
      icon: Icon
      name: () => string
    }
  }
}

/**
 * TODO: Investigate the burden of the beforeEach function.
 * It runs on every route change and can be a performance bottleneck.
 */
export async function beforeEach(to: RouteLocationNormalized) {
  useLoadingIndicatorStore().startLoadingIndicator()

  const auth = to.meta.auth ?? true
  if (!auth) {
    // no auth required
    return
  }

  const authStore = useAuthStore()
  await authStore.ensureToken()
  if (!authStore.isTokenValid()) {
    const redirectUrl = useSessionStorage<string>('redirect-url', '/')
    redirectUrl.value = to.fullPath
    return { name: 'AppLogout' }
  }

  if (auth === true) {
    return
  }

  if (!authStore.hasRoleOrAuthorization(auth)) {
    return createErrorLocation(to, StatusCodes.FORBIDDEN)
  }
}

export async function afterEach(...[to, from, failure]: Parameters<NavigationHookAfter>) {
  const loadingIndicatorStore = useLoadingIndicatorStore()
  if (failure) {
    loadingIndicatorStore.finishLoadingIndicator()
  }

  await useUserSettingsStore().syncLocale()

  // Pushing to the same route won't trigger the setup function again, so we manually stop the loading indicator if the path is the same`
  if (to.path === from.path) {
    loadingIndicatorStore.finishLoadingIndicator()
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'AppHome',
      path: '',
      alias: '/',
      redirect: () => {
        return {
          name: 'AppDefaultDashboard',
        }
      },
    },
    ...Auditor,
    ...Authorization,
    ...GeneralConfiguration,
    ...Calendar,
    ...Choice,
    ...Countries,
    ...Currency,
    ...Dashboard,
    ...DataTransfer,
    ...DocumentTemplate,
    ...Error,
    ...File,
    ...FileType,
    ...Import,
    ...LegalForm,
    ...Layout,
    ...LayoutCollections,
    ...Period,
    ...SavedFilter,
    ...Security,
    ...Status,
    ...Support,
    ...SystemMessage,
    ...TaskType,
    ...UnitHierarchy,
    ...Unit,
    ...UserGroup,
    ...User,
    ...WorkflowFieldConfiguration,
    ...Workflow,
  ],
})

router.beforeEach((to) => beforeEach(to))

router.onError(async (error, to: RouteLocationNormalized) => {
  useLoadingIndicatorStore().finishLoadingIndicator()

  if (
    error.message.includes('error loading dynamically imported module') ||
    error.message.includes('Failed to fetch dynamically imported module')
  ) {
    window.location.href = to.fullPath
    return
  }

  const statusCode = axios.isAxiosError(error) ? error.response?.status : undefined
  if (statusCode) {
    if (statusCode === StatusCodes.FORBIDDEN) {
      await router.push(createErrorLocation(to, StatusCodes.FORBIDDEN))
      return
    }

    if ([StatusCodes.NOT_FOUND, StatusCodes.MISDIRECTED_REQUEST].includes(statusCode)) {
      await router.push(createErrorLocation(to, StatusCodes.NOT_FOUND))
      return
    }

    if (statusCode === StatusCodes.SERVICE_UNAVAILABLE) {
      const redirectUrl = useSessionStorage<string>('maintenance-redirect-url', '/')
      redirectUrl.value = to.fullPath

      await router.push(createErrorLocation(to, StatusCodes.SERVICE_UNAVAILABLE))
      return
    }

    if (statusCode === StatusCodes.UNAUTHORIZED) {
      // Do nothing. Axios will handle 401 redirect to login page or two factor page
      return
    }
  }

  await router.push(createErrorLocation(to))
})

router.afterEach((to, from, failure) => afterEach(to, from, failure))
export default router
