import $ from 'jquery'

$.fn.dropDownMenu = function () {
  const settings = {
    dropDownEvent: 'click',
  }

  let menuTimeout: ReturnType<typeof setTimeout>
  let submenu: JQuery<HTMLUListElement>
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const menuBox = this
  const $menuItem = menuBox.find('li') // get all menu items

  const closeMenu = (activeMenuFilter: JQuery<HTMLUListElement>) => {
    activeMenuFilter
      .removeClass('active')
      .find('li')
      .removeClass('active')
      .end()
      .find('ul ul')
      .fadeOut('fast')
  }

  const openMenu = (
    menuBox: JQuery<HTMLElement>,
    thisItem: JQuery<HTMLElement>,
    activeMenu: boolean
  ) => {
    const thisMenu = thisItem.closest('nav.drop-down-menu').attr('id')
    const activeMenuFilter = $('nav.drop-down-menu').filter('.active') as JQuery<HTMLUListElement>
    const activeMenuId = activeMenuFilter.attr('id')

    if (thisMenu !== activeMenuId) {
      closeMenu(activeMenuFilter)
    }

    if (!activeMenu) {
      menuBox.addClass('active')
    }

    thisItem.addClass('active').children('ul').show().addClass('active')
  }

  $menuItem.on({
    click: function (event) {
      const thisItem = $(this) as JQuery<HTMLElement>
      const activeMenu = menuBox.hasClass('active')
      submenu = thisItem.find('ul') as JQuery<HTMLUListElement>

      if (!activeMenu && submenu.length && settings.dropDownEvent === 'click') {
        openMenu(menuBox, thisItem, activeMenu)
        event.preventDefault()
      }
    },
    mouseenter: function (e) {
      e.stopPropagation()

      const thisItem = $(this)
      const activeMenu = menuBox.hasClass('active')
      submenu = thisItem.find('ul')

      thisItem.closest('ul').find('li').removeClass('active').find('ul').hide()

      if (
        (activeMenu && submenu.length) ||
        (settings.dropDownEvent === 'hover' && submenu.length)
      ) {
        if (settings.dropDownEvent === 'hover') {
          // set a timeout for the hover menu to drop down
          menuTimeout = setTimeout(() => {
            openMenu(menuBox, thisItem, activeMenu)
          }, 400)
        } else {
          openMenu(menuBox, thisItem, activeMenu)
        }
      }
    },
    mouseleave: () => {
      if (settings.dropDownEvent === 'hover') {
        clearTimeout(menuTimeout)
      }
    },
  })

  $('body').on('click', (event) => {
    const activeMenuFilter = $('nav.drop-down-menu').filter('.active') as JQuery<HTMLUListElement>

    if (activeMenuFilter.length) {
      const targetParents = $(event.target).parents('nav.drop-down-menu')
      if (targetParents.length !== 1 || !$(event.target).closest('ul').hasClass('first-level')) {
        closeMenu(activeMenuFilter)
      }
    }
  })
}
