import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import invariant from 'tiny-invariant'
import { itemUnitHierarchyValueApi } from '@js/api/itemUnitHierarchyValueApi'
import type { LayoutItem } from '@js/model/layout'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { Period } from '@js/model/period'
import type { MaybeRef } from '@vueuse/core'

export const itemUnitHierarchyValues = createQueryKeys('itemUnitHierarchyValues', {
  single: (
    itemId?: MaybeRef<LayoutItem['id']>,
    unitHierarchyId?: MaybeRef<UnitHierarchy['id']>,
    periodId?: MaybeRef<Period['id']>
  ) => ({
    queryKey: [itemId, unitHierarchyId, periodId],
    queryFn: () => {
      const itemIdValue = toValue(itemId)
      invariant(itemIdValue)
      const unitHierarchyIdValue = toValue(unitHierarchyId)
      invariant(unitHierarchyIdValue)
      const periodIdValue = toValue(periodId)
      invariant(periodIdValue)

      return itemUnitHierarchyValueApi
        .fetchById(itemIdValue, unitHierarchyIdValue, periodIdValue)
        .then((response) => response.data)
    },
  }),
})
