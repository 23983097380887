<template>
  <nav id="user-menu" class="drop-down-menu user included">
    <ul class="first-level">
      <MenuItem v-for="item in menuItems" :key="item.name" :item="item" />
    </ul>
  </nav>
</template>

<script setup lang="ts">
import '@js/utilities/drop-down-menu.jquery'
import { computed, nextTick, onMounted, onUpdated } from 'vue'
import $ from 'jquery'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@js/query'
import MenuItem from '@js/components/menu/MenuItem.vue'

const { data: userMenuJson } = useQuery(queries.menu.userMenuJson)
const menuItems = computed(() => {
  return userMenuJson.value?.data?.['children'] ?? []
})

onUpdated(() => {
  nextTick(() => {
    $('#user-menu').dropDownMenu()
  })
})
onMounted(() => {
  nextTick(() => {
    $('#user-menu').dropDownMenu()
  })
})
</script>

<style>
#user-menu.drop-down-menu {
  margin-right: theme('spacing[1.5]');

  li,
  a {
    color: theme('colors.white');
  }

  li {
    display: inline-block;
    position: relative;
    transition: background-color 0.3s ease;

    &:hover,
    &.active {
      background-color: theme('colors.gray.800');

      > ul {
        display: block;
      }
    }
  }

  span {
    cursor: pointer;
  }

  a {
    display: block;
    line-height: theme('lineHeight.tight');
    padding: theme('spacing[1.5]') 12px;

    &[class*='icon-']::before {
      position: relative;
      top: -1px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  ul.first-level {
    text-align: right;
    white-space: nowrap;

    > li > a,
    > li > span {
      height: var(--app-header-height);
      line-height: var(--app-header-height);
      padding: 0 theme('spacing[1.5]');
    }

    ul {
      border-bottom-left-radius: theme('borderRadius.sm');
      border-bottom-right-radius: theme('borderRadius.sm');
      border-top: 1px solid hsl(0deg 0% 0% / 25%);
      box-shadow: theme('boxShadow.md');
      display: none;
      min-width: theme('width.48');
      padding: 5px 0;
      position: absolute;
      right: 0;
      text-align: center;
      top: var(--app-header-height);
      width: 100%;
      z-index: theme('zIndex.40');

      &,
      .drop-down-menu-separator > span {
        background-color: theme('colors.gray.800');
      }

      li {
        display: block;
        width: auto;

        &:hover,
        &.active {
          background-color: theme('colors.transparent-white-20');
        }
      }

      ul {
        border-top-right-radius: theme('borderRadius.sm');
        left: var(--main-menu-item-width);
        top: -5px;
      }
    }
  }

  &.user {
    margin-right: theme('spacing[1.5]');

    ul.first-level {
      text-align: right;

      ul {
        min-width: theme('width.48');
        right: 0;
        text-align: center;
        width: 100%;
      }
    }
  }

  .drop-down-menu-arrow-right {
    /* Specificity (".drop-down-menu a") */
    padding-right: 30px !important;

    &::after {
      background-color: theme('colors.white');
      content: '';
      display: inline-block;
      font-size: 1em;
      height: 1em;
      mask-image: url('@icons/arrow-right.svg?url');
      mask-size: cover;
      position: absolute;
      right: 10px;
      top: calc(50% - 5px);
      vertical-align: middle;
      width: 1em;
    }
  }

  .drop-down-menu-separator {
    /* Specificity of the drop-down styles, and removes the need of re-declaring it for :hover */
    background-color: theme('colors.gray.400') !important;
    height: 1px;
    line-height: 10px;
    margin: 5px 10px;
    text-align: center;

    span {
      background-color: theme('colors.gray.700');
      color: theme('colors.gray.200');
      display: inline-block;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 0 10px;
      position: relative;
      top: -5px;
    }
  }

  @media (width >= 905px) {
    ul.first-level > li > a,
    ul.first-level > li > span {
      font-size: theme('fontSize.lg');
      letter-spacing: 0.5px;
      padding: 0 9px;
    }
  }
}
</style>
