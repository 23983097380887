import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { AnyUnit, Unit } from '@js/model/unit'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export const basePath = '/api/units'

export type UnitCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'exclude-unassigned'?: boolean
  type?: 'unit' | 'legalunit' | 'permanentestablishment' | 'organisationalgroup'
  'sort[auditor.name]'?: SortingDirection
  'sort[branch.name]'?: SortingDirection
  'sort[contactUser.username]'?: SortingDirection
  'sort[country.nameShort]'?: SortingDirection
  'sort[countryFounded.nameShort]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[currency.iso4217code]'?: SortingDirection
  'sort[description]'?: SortingDirection
  'sort[id]'?: SortingDirection
  'sort[legalForm.name]'?: SortingDirection
  'sort[legalName]'?: SortingDirection
  'sort[name]'?: SortingDirection
  'sort[parentLegalUnit.name]'?: SortingDirection
  'sort[parentLegalUnitIncomeTax.name]'?: SortingDirection
  'sort[parentLegalUnitVat.name]'?: SortingDirection
  'sort[refId]'?: SortingDirection
  'sort[registerNumber]'?: SortingDirection
  'sort[registryPlace]'?: SortingDirection
  'sort[taxAdvisor.name]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
  'sort[validFrom]'?: SortingDirection
  'sort[validTo]'?: SortingDirection
  'sort[vatNumber]'?: SortingDirection
  'sort[verified]'?: SortingDirection
  search?: string | Array<string>
}

function fetchByQuery(query?: UnitCollectionQuery) {
  return axios.get<HydraCollectionResponse<Unit>>(basePath, {
    params: { ...query },
  })
}

function fetchAll() {
  return fetchByQuery({ pagination: false, 'sort[name]': 'ASC', 'exclude-unassigned': false })
}
function fetchAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

function updateAssignedUserGroups(id: number, updatedGroups: Array<NonNullable<UserGroup['@id']>>) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

function fetchAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

function fetchInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

function fetchById(id: AnyUnit['id']) {
  return axios.get<AnyUnit>(`${basePath}/${id}`)
}

export const unitApi = {
  basePath,
  fetchByQuery,
  fetchAll,
  fetchAssignedUserGroups,
  fetchById,
  fetchAssignedUsers,
  updateAssignedUserGroups,
  updateAssignedUsers,
  fetchInheritedAssignedUsers,
}
