import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { type ConditionType, transitionConditionTypeApi } from '@js/api/transitionConditionTypeApi'
import type { MaybeRef } from '@vueuse/core'

export const conditionTypes = createQueryKeys('conditionTypes', {
  all: {
    queryKey: null,
    queryFn: () => transitionConditionTypeApi.fetchAll(),
  },
  single: (id: MaybeRef<ConditionType['id']>) => {
    return {
      queryKey: [id],
      queryFn: () =>
        transitionConditionTypeApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
