<template>
  <div v-if="systemMessages.length > 0" class="z-10">
    <SystemMessage
      v-for="message in systemMessages.slice(0, 10)"
      :key="message.id"
      :message="message"
      @close="hideSystemMessage"
    />
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery'
import axios from 'axios'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { computed } from 'vue'
import { queries } from '@js/query'
import useHandleAxiosErrorResponse from '@js/composable/useHandleAxiosErrorResponse'
import Routing from '@js/Routing'
import SystemMessage from '@js/components/system-messages/SystemMessage.vue'

const { resolveNotification } = useHandleAxiosErrorResponse()

const { data: systemMessagesResponse } = useQuery(queries.systemMessages.active)

const systemMessages = computed(() => systemMessagesResponse.value ?? [])

const queryClient = useQueryClient()
function hideSystemMessage({ element, id }: { element: HTMLElement; id: number }) {
  axios
    .post(Routing.generate('u2_systemmessagedisplay_hidemessage', { id }))
    .then(() => {
      queryClient.invalidateQueries({
        queryKey: queries.systemMessages.active.queryKey,
      })
    })
    .catch((error) => {
      resolveNotification(error)

      $(element).slideDown(400)
    })
  $(element).slideUp(400)
}
</script>
