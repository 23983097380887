import axios from 'axios'
import type { DynamicAssetId } from '@js/model/dynamicAsset'

export const basePath = '/api/dynamic-assets'

export const dynamicAssetApi = {
  fetch: function (id: DynamicAssetId) {
    return axios.get<Blob>(`${basePath}/${id}`, { responseType: 'blob' })
  },
  update: function (id: DynamicAssetId, payload?: File) {
    const formData = new FormData()
    if (payload) {
      formData.append('uploadedFile', payload)
    }

    return axios.post(`${basePath}/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
