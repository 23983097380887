import { createQueryKeys } from '@lukemorales/query-key-factory'
import { dynamicAssetApi } from '@js/api/dynamicAssetApi'
import type { DynamicAssetId } from '@js/model/dynamicAsset'

export const dynamicAssets = createQueryKeys('dynamicAssets', {
  single: (id: DynamicAssetId) => {
    return {
      queryKey: [id],
      queryFn: () =>
        dynamicAssetApi.fetch(id).then(({ data, headers }) => {
          return new File(
            [data],
            headers['content-disposition'].includes('default') ? 'default' : 'custom',
            { type: headers['content-type'] }
          )
        }),
    }
  },
})
