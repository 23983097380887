<template>
  <TransitionRoot :show="isOpen" as="template" appear @after-leave="query = ''">
    <Dialog as="div" class="relative z-40" @close="close">
      <!-- OVERLAY -->
      <TransitionChild
        as="div"
        class="fixed inset-0 bg-black/30"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        aria-hidden="true"
      />

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto max-w-xl transform overflow-hidden rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox @update:model-value="onItemSelect">
              <div class="relative border-b border-gray-100">
                <AppLoader
                  v-if="loading"
                  size="small"
                  class="pointer-events-none absolute left-4 top-3.5 size-5 text-slate-400"
                />
                <SvgIcon
                  v-else
                  icon="search"
                  size="large"
                  class="pointer-events-none absolute left-4 top-3.5 text-slate-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-12 pr-4 text-slate-800 placeholder-slate-400 focus:ring-0"
                  :placeholder="Translator.trans('u2.search')"
                  @change="onQueryUpdate"
                  @keydown.enter="onEnter"
                />
              </div>

              <template v-if="query === '' && recentSearches.length > 0">
                <h2 class="mt-4 pl-6 pr-4 text-sm font-semibold text-gray-500">
                  {{ Translator.trans('u2.search.recent_searches') }}
                </h2>
                <ComboboxOptions
                  static
                  class="max-h-96 scroll-py-3 list-none overflow-y-auto py-3 pl-0"
                >
                  <GlobalSearchItem
                    v-for="recentSearch in recentSearches.slice(0, 5)"
                    :key="recentSearch.name + recentSearch.url"
                    :item="recentSearch"
                    @click="onItemSelect(recentSearch)"
                  />
                </ComboboxOptions>
              </template>

              <ComboboxOptions
                v-if="results.length > 0"
                static
                class="max-h-96 scroll-py-3 list-none overflow-y-auto py-3 pl-0"
              >
                <GlobalSearchItem
                  v-for="result in results"
                  :key="result.name + result.url"
                  :item="result"
                  @click="onItemSelect(result)"
                />
              </ComboboxOptions>

              <div
                v-if="query !== '' && results.length === 0"
                class="px-6 py-14 text-center sm:px-14"
              >
                <p class="mt-2 text-gray-500">
                  {{ Translator.trans('u2_table.no_results_that_match_search') }}
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { onKeyStroke, useStorage } from '@vueuse/core'
import { ref } from 'vue'
import uniqBy from 'lodash/uniqBy'
import { useRouter } from 'vue-router'
import { useGlobalSearch } from '@js/composable/useGlobalSearch'
import AppLoader from '@js/components/loader/AppLoader.vue'
import GlobalSearchItem from '@js/components/GlobalSearchItem.vue'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import type { SearchResult } from '@js/composable/useGlobalSearch'
import type { Ref } from 'vue'

withDefaults(
  defineProps<{
    isOpen: boolean
  }>(),
  {
    isOpen: false,
  }
)
const { results, query, loading } = useGlobalSearch()
const recentSearches = useStorage<Array<SearchResult>>('recentSearches', [], undefined, {
  flush: 'sync',
})
const router = useRouter()

const item = ref()
const onItemSelect = (value: SearchResult) => {
  item.value = value
  addToRecent(item)
  close()
}

function onEnter() {
  if (item.value) {
    router.push(item.value.url)
    addToRecent(item)
  }
  close()
}

function addToRecent(item: Ref<SearchResult>) {
  const maxRecentResults = 10
  recentSearches.value = uniqBy([item.value, ...recentSearches.value], 'url')
    // Purge old results
    .slice(0, maxRecentResults)
    .filter((item) => {
      return !router.resolve(item.url).name?.toString().includes('Error404')
    })
}
const emit = defineEmits<(event: 'close') => void>()
function close() {
  emit('close')
}

onKeyStroke('Escape', () => {
  close()
})
const onQueryUpdate = (event: Event) => {
  if (event.target instanceof HTMLInputElement) {
    query.value = event.target.value
  }
}
</script>
