<template>
  <a
    v-if="routerLink"
    v-bind="$attrs"
    :role="role"
    :href="href"
    :class="[
      'inline-flex items-center gap-1',
      {
        'router-link-active': routerLink.isActive.value,
        'router-link-exact-active': routerLink.isExactActive.value,
      },
    ]"
    @click="navigate"
  >
    <SvgIcon v-if="icon" :icon="icon" />
    <slot />
  </a>
  <a
    v-else
    class="inline-flex items-center gap-1"
    v-bind="$attrs"
    rel="noopener"
    :role="role"
    :href="href"
  >
    <SvgIcon v-if="icon" :icon="icon" />
    <slot />
  </a>
</template>
<script setup lang="ts">
import invariant from 'tiny-invariant'
import { computed, toRefs, useAttrs } from 'vue'
import { useLink } from 'vue-router'
import SvgIcon from '@js/components/SvgIcon.vue'
import type { RouteLocationRaw } from 'vue-router'
import type { Icon } from '@js/utilities/name-lists'

const props = defineProps<{
  to: RouteLocationRaw
  icon?: Icon
  disabled?: boolean
}>()

defineOptions({ inheritAttrs: false })
defineSlots<{ default?: (props: Record<string, never>) => unknown }>()

const { to, disabled } = toRefs(props)

const isExternalRouterLink = computed(() => {
  return (
    typeof props.to === 'string' && (props.to.startsWith('http') || props.to.startsWith('mailto'))
  )
})

const routerLink = isExternalRouterLink.value ? undefined : useLink({ to })

const href = computed(() => {
  if (disabled.value) {
    return undefined
  }
  if (isExternalRouterLink.value) {
    return to.value as string
  }
  invariant(routerLink)
  return routerLink.href.value
})

const attrs = useAttrs()
const role = computed(() => (attrs.role as string) ?? 'link')

const navigate = (event: MouseEvent) => {
  // don't navigate if disabled
  if (disabled.value) return
  // don't navigate with control keys
  if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) return
  // don't navigate when preventDefault called
  if (event.defaultPrevented) return
  // don't navigate on right click
  if (event.button !== undefined && event.button !== 0) return
  // don't navigate if `target="_blank"`
  if (event.currentTarget && (event.currentTarget as HTMLElement).getAttribute) {
    const target = (event.currentTarget as HTMLElement).getAttribute('target') ?? ''
    if (/\b_blank\b/i.test(target)) return
  }
  event.preventDefault()
  invariant(routerLink)
  routerLink.navigate()
}
</script>
