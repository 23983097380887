import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { Import, ImportRequest, ImportType } from '@js/model/import'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

const basePath = '/api/imports'

function fetchByIri(iri: Import['@id']) {
  return axios.get<Import>(iri)
}

function fetchById(id: Import['id']) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'bindings.bindingId'?: string | Array<string>
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<Import>>(basePath, {
    params: query,
  })
}

export const importApi = {
  basePath,
  fetchByIri,
  fetchById,
  fetchByQuery,
  startImport: function (importRequest: ImportRequest) {
    return axios.post<Import>('/api/imports', importRequest)
  },
  fetchImportTypes: function () {
    return axios.get<HydraCollectionResponse<ImportType>>('/api/import-types')
  },
  fetchImportType: function (id: ImportType['shortName']) {
    return axios.get<ImportType>(`/api/import-types/${id.replaceAll(/_/g, '-')}`)
  },
}
