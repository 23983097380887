import axios from 'axios'
import type { Task } from '@js/model/task'
import type { Comment } from '@js/model/comment'

const basePath = '/api/comments'
function fetchById(taskId: Task['id']) {
  return axios.get<Comment>(`${basePath}/${taskId}`)
}

export const commentApi = {
  fetchById,
  basePath,
}
