import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/currency/CurrencyEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.currency')} #${params.id}`
      },
    },
    name: 'CurrencyEdit',
    path: '/configuration-data/currency/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/currency/CurrencyNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'CurrencyNew',
    path: '/configuration-data/currency/new',
  },
  {
    component: () => import('@js/pages/currency/CurrencyList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.currency.plural'),
      },
    },
    name: 'CurrencyList',
    path: '/configuration-data/currency',
  },
] as Array<RouteRecordRaw>
