import { useQueryClient } from '@tanstack/vue-query'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import { createErrorLocation } from '@js/router/helpers'
import Translator from '@js/translator'
import { queries } from '@js/query'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/import/ImportList.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.import.imports'),
      },
    },
    name: 'ImportList',
    path: '/import',
  },
  {
    component: () => import('@js/pages/import/ImportStart.vue'),
    meta: {
      layout: BaseLayout,
      title: () => Translator.trans('u2.import.imports'),
    },
    name: 'ImportStart',
    path: '/import/:configurationKeySlug/start',
    props: (route: RouteLocation) => ({ configurationKeySlug: route.params.configurationKeySlug }),
    beforeEnter: async (to) => {
      const queryClient = useQueryClient()
      return await queryClient
        .fetchQuery({
          ...queries.importTypes.single(String(to.params.configurationKeySlug)),
        })
        .then(() => {
          return
        })
        .catch((error) => {
          return createErrorLocation(to, error.response.status)
        })
    },
  },
  {
    component: () => import('@js/pages/import/ImportResult.vue'),
    meta: {
      layout: BaseLayout,
      title: (params: { id: number }) =>
        Translator.trans('u2_core.import.result') + ' #' + params.id,
    },
    name: 'ImportResult',
    path: '/import/:id/result',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
] as Array<RouteRecordRaw>
