import { z } from 'zod'
import { defaultValidationMessages } from '@js/helper/form/validation-messages'

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (
    issue.code === 'too_small' &&
    issue.type === 'string' &&
    issue.minimum === 1 &&
    ctx.data === ''
  ) {
    return { message: defaultValidationMessages.required() }
  }
  if (
    issue.code === 'invalid_type' &&
    issue.expected === 'string' &&
    (ctx.data === null || ctx.data === undefined)
  ) {
    return { message: defaultValidationMessages.required() }
  }
  return { message: ctx.defaultError }
}

z.setErrorMap(customErrorMap)
