import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/country/CountryList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.country.plural'),
      },
    },
    name: 'CountryList',
    path: '/configuration/country',
  },
  {
    component: () => import('@js/pages/country/CountryEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => `${Translator.trans('u2.country')} #${params.id}`,
    },
    name: 'CountryEdit',
    path: '/configuration/country/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/country/CountryNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'CountryNew',
    path: '/configuration/country/new',
  },
] as Array<RouteRecordRaw>
