import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteRecordRaw } from 'vue-router'

type ChoiceRecord = {
  slug: string
  routeName: string
  title: () => string
  readableName: () => string
  collectionEndpoint: string
}

const choices: Array<ChoiceRecord> = [
  {
    slug: 'advice-type',
    routeName: 'AdviceTypeList',
    title: () => Translator.trans('u2_tam.advice_type.plural'),
    readableName: () => Translator.trans('u2_tam.advice_type'),
    collectionEndpoint: '/api/configuration/field/advice-types',
  },
  {
    slug: 'assessment-type',
    routeName: 'AssessmentTypeList',
    title: () => Translator.trans('u2_tcm.assessment_type.plural'),
    readableName: () => Translator.trans('u2_tcm.assessment_type'),
    collectionEndpoint: '/api/configuration/field/assessment-types',
  },
  {
    slug: 'billing-type',
    routeName: 'BillingTypeList',
    title: () => Translator.trans('u2_financial.billing_type.plural'),
    readableName: () => Translator.trans('u2_financial.billing_type'),
    collectionEndpoint: '/api/configuration/field/billing-types',
  },
  {
    slug: 'business-activity',
    routeName: 'BusinessActivityList',
    title: () => Translator.trans('u2_tpm.main_business_activity.plural'),
    readableName: () => Translator.trans('u2_tpm.main_business_activity'),
    collectionEndpoint: '/api/configuration/field/business-activities',
  },
  {
    slug: 'business-case',
    routeName: 'BusinessCaseList',
    title: () => 'Business Cases',
    readableName: () => 'Business Cases',
    collectionEndpoint: '/api/configuration/field/business-cases',
  },
  {
    slug: 'collateralisation-type',
    routeName: 'CollateralisationTypeList',
    title: () => Translator.trans('u2_financial.collateralisation_type.plural'),
    readableName: () => Translator.trans('u2_financial.collateralisation_type'),
    collectionEndpoint: '/api/configuration/field/collateralisation-types',
  },
  {
    slug: 'contract-type',
    routeName: 'ContractTypeList',
    title: () => Translator.trans('u2_contractmanagement.contract_type.plural'),
    readableName: () => Translator.trans('u2_contractmanagement.contract_type'),
    collectionEndpoint: '/api/configuration/field/contract-types',
  },
  {
    slug: 'deadline-type',
    routeName: 'DeadlineTypeList',
    title: () => Translator.trans('u2_core.deadline_type.plural'),
    readableName: () => Translator.trans('u2_core.deadline_type'),
    collectionEndpoint: '/api/configuration/field/deadline-types',
  },
  {
    slug: 'declaration-type',
    routeName: 'DeclarationTypeList',
    title: () => Translator.trans('u2_tcm.declaration_type.plural'),
    readableName: () => Translator.trans('u2_tcm.declaration_type'),
    collectionEndpoint: '/api/configuration/field/declaration-types',
  },
  {
    slug: 'instrument-id-type',
    routeName: 'InstrumentIdTypeList',
    title: () => 'Instrument Id Types',
    readableName: () => 'Instrument Id Type',
    collectionEndpoint: '/api/configuration/field/instrument-id-types',
  },
  {
    slug: 'line-of-business',
    routeName: 'LineOfBusinessList',
    title: () => 'Line of Businesses',
    readableName: () => 'Line of Business',
    collectionEndpoint: '/api/configuration/field/line-of-businesses',
  },
  {
    slug: 'loss-restriction',
    routeName: 'LossRestrictionList',
    title: () => Translator.trans('u2_tam.loss_restriction.plural'),
    readableName: () => Translator.trans('u2_tam.loss_restriction'),
    collectionEndpoint: '/api/configuration/field/loss-restrictions',
  },
  {
    slug: 'loss-type',
    routeName: 'LossTypeList',
    title: () => Translator.trans('u2_tam.loss_type.plural'),
    readableName: () => Translator.trans('u2_tam.loss_type'),
    collectionEndpoint: '/api/configuration/field/loss-types',
  },
  {
    slug: 'pricing-method',
    routeName: 'PricingMethodList',
    title: () => Translator.trans('u2_financial.pricing_method.plural'),
    readableName: () => Translator.trans('u2_financial.pricing_method'),
    collectionEndpoint: '/api/configuration/field/pricing-methods',
  },
  {
    slug: 'restriction-reason',
    routeName: 'RestrictionReasonList',
    title: () => Translator.trans('u2_tam.restriction_reason.plural'),
    readableName: () => Translator.trans('u2_tam.restriction_reason'),
    collectionEndpoint: '/api/configuration/field/restriction-reasons',
  },
  {
    slug: 'risk-type',
    routeName: 'RiskTypeList',
    title: () => Translator.trans('u2_tam.risk_type.plural'),
    readableName: () => Translator.trans('u2_tam.risk_type'),
    collectionEndpoint: '/api/configuration/field/risk-types',
  },
  {
    slug: 'service-type',
    routeName: 'ServiceTypeList',
    title: () => 'Service Types',
    readableName: () => 'Service Type',
    collectionEndpoint: '/api/configuration/field/service-types',
  },
  {
    slug: 'specification',
    routeName: 'SpecificationList',
    title: () => Translator.trans('u2_tam.specification.plural'),
    readableName: () => Translator.trans('u2_tam.specification'),
    collectionEndpoint: '/api/configuration/field/specifications',
  },
  {
    slug: 'tax-credit-type',
    routeName: 'TaxCreditTypeList',
    title: () => Translator.trans('u2_tam.tax_credit_type.plural'),
    readableName: () => Translator.trans('u2_tam.tax_credit_type'),
    collectionEndpoint: '/api/configuration/field/tax-credit-types',
  },
  {
    slug: 'tax-type',
    routeName: 'TaxTypeList',
    title: () => Translator.trans('u2_financial.tax_type.plural'),
    readableName: () => Translator.trans('u2_financial.tax_type'),
    collectionEndpoint: '/api/configuration/field/tax-types',
  },
  {
    slug: 'transaction-type',
    routeName: 'TransactionTypeList',
    title: () => Translator.trans('u2_financial.transaction_type.plural'),
    readableName: () => Translator.trans('u2_financial.transaction_type'),
    collectionEndpoint: '/api/configuration/field/transaction-types',
  },
  {
    slug: 'trace-id',
    routeName: 'TraceIdList',
    title: () => Translator.trans('u2.choice.trace_id.plural'),
    readableName: () => Translator.trans('u2.choice.trace_id.'),
    collectionEndpoint: '/api/configuration/field/trace-ids',
  },
]

export const getCurrentChoice = (slug: string) => {
  return choices.find((choice) => choice.slug === slug)
}
export default [
  ...choices.map((choice) => {
    return {
      name: choice.routeName,
      path: `/configuration/fields/${choice.slug}`,
      component: () => import('@js/pages/configuration/ChoiceList.vue'),
      meta: {
        auth: 'ROLE_ADMIN',
        layout: ConfigurationDataLayout,
        title: choice.title,
        globalSearch: {
          icon: 'config',
          name: choice.title,
        },
      },
      props: () => ({
        fieldId: choice.slug,
        readableName: choice.readableName(),
        resourceCollectionEndpoint: choice.collectionEndpoint,
      }),
    }
  }),
] as Array<RouteRecordRaw>
