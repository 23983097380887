import { createQueryKeys } from '@lukemorales/query-key-factory'
import { savedFilterApi } from '@js/api/savedFilterApi'

export const savedFilters = createQueryKeys('savedFilters', {
  all: { queryKey: null, queryFn: () => savedFilterApi.fetchAll() },
  favouriteSavedFilters: { queryKey: null, queryFn: () => savedFilterApi.fetchMyFavourites() },
  single: (id) => {
    return {
      queryKey: [id],
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => savedFilterApi.fetchAssignedUsers(id).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            savedFilterApi.fetchInheritedAssignedUsers(id).then((response) => response.data),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            savedFilterApi.fetchAssignedUserGroups(id).then((response) => response.data),
        },
        subscriptions: {
          queryKey: null,
          queryFn: () => savedFilterApi.fetchSubscriptions(id).then((response) => response.data),
        },
      },
    }
  },
})
