import { taskTypeUrlPatternToShortNameMap } from '@js/model/task'
import type { RouteLocation } from 'vue-router'

export default function (to: RouteLocation) {
  for (const taskTypeUrlPattern in taskTypeUrlPatternToShortNameMap) {
    if (to.path.includes(taskTypeUrlPattern)) {
      return taskTypeUrlPatternToShortNameMap[taskTypeUrlPattern]
    }
  }
  throw new Error(`No short name found for URL ${to.path}`)
}
