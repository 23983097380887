import type { ApiResource, ApiResourceId } from '@js/types'

export type UserPermission = ApiResource & {
  id: number
  mask: number
  user: ApiResourceId
}

export type GroupPermission = ApiResource & {
  id: number
  mask: number
  group: ApiResourceId
}

export const PermissionMasks = {
  VIEW: 1, // 1 << 0
  EDIT: 4, // 1 << 2
  DELETE: 8, // 1 << 3
  MANAGE: 128, // 1 << 7
} as const
export type PermissionMask = (typeof PermissionMasks)[keyof typeof PermissionMasks]

export const CompositePermissionMasks = {
  VIEW: 1,
  EDIT: PermissionMasks.VIEW | PermissionMasks.EDIT, // 5
  DELETE: PermissionMasks.VIEW | PermissionMasks.EDIT | PermissionMasks.DELETE, // 13
  MANAGE:
    PermissionMasks.VIEW | PermissionMasks.EDIT | PermissionMasks.DELETE | PermissionMasks.MANAGE, // 141
} as const

export const permissionOptions = [
  { id: CompositePermissionMasks.VIEW.toString(), name: 'Read' },
  { id: CompositePermissionMasks.EDIT.toString(), name: 'Read, Edit' },
  { id: CompositePermissionMasks.DELETE.toString(), name: 'Read, Edit, Delete' },
  { id: CompositePermissionMasks.MANAGE.toString(), name: 'Read, Edit, Delete, Manage' },
]
