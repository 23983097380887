import axios from 'axios'
import type { Dashboard } from '@js/model/dashboard'
import type { HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

const basePath = '/api/dashboards'
function fetchAll() {
  return axios.get<HydraCollectionResponse<Dashboard>>(basePath, {
    params: { pagination: false },
  })
}
function fetchById(id: Dashboard['id']) {
  return axios.get<Dashboard>(`${basePath}/${id}`)
}

function fetchAssignedUserGroups(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

function updateAssignedUserGroups(
  id: Dashboard['id'],
  updatedGroups: Array<NonNullable<UserGroup['@id']>>
) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

function fetchAssignedUsers(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: Dashboard['id'], updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

function fetchInheritedAssignedUsers(id: Dashboard['id']) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}
export const dashboardApi = {
  delete: function (id: Dashboard['id']) {
    return axios.delete(`${basePath}/${id}`)
  },
  fetchAll,
  fetchById,
  fetchAssignedUserGroups,
  fetchAssignedUsers,
  updateAssignedUserGroups,
  updateAssignedUsers,
  fetchInheritedAssignedUsers,
}
