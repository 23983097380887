import { acceptHMRUpdate, defineStore } from 'pinia'
import invariant from 'tiny-invariant'
import { ref } from 'vue'
import { entityApi } from '@js/api/entityApi'
import { type TaskShortName } from '@js/model/task'

/**
 * This provides task information from the backend.
 * It is a temporary solution until we can provide all data via concrete API's.
 * Try to avoid using it where possible and remove it ASAP.
 */
export const useTaskInfoStore = defineStore('task-info', () => {
  const assignee = ref<{ valid: boolean; id: number }>()
  const extra = ref<{ listPath?: string }>()
  const id = ref<number>()
  const optionsForNew = ref<Record<string, string> | null>()
  const readableName = ref<string>()
  const shortName = ref<TaskShortName>()
  const taskId = ref<string>()
  async function refresh() {
    invariant(shortName.value)

    if (id.value) {
      await fetchTaskInformation(shortName.value, id.value)
      return
    }
    await fetchTaskMetadata(shortName.value)
  }
  async function fetchTaskInformation(taskShortName: string, taskTypeId: number) {
    const { data } = await entityApi.fetchTaskInformation(taskShortName, taskTypeId)
    assignee.value = data.assignee
    extra.value = data.extra
    id.value = data.id
    optionsForNew.value = data.optionsForNew
    readableName.value = data.readableName
    shortName.value = data.shortName
    taskId.value = data.taskId
  }
  async function fetchTaskMetadata(taskShortName: string) {
    const { data } = await entityApi.fetchTaskMetadata(taskShortName)
    assignee.value = undefined
    extra.value = data.extra
    id.value = undefined
    optionsForNew.value = undefined
    readableName.value = data.readableName
    shortName.value = data.shortName
    taskId.value = undefined
  }

  return {
    assignee,
    extra,
    id,
    optionsForNew,
    readableName,
    shortName,
    taskId,
    refresh,
    fetchTaskInformation,
    fetchTaskMetadata,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskInfoStore, import.meta.hot))
}
