import axios from 'axios'
import Routing from '@js/Routing'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { Layout, UnitViewData } from '@js/model/layout'
import type { Period } from '@js/model/period'
import type { Unit } from '@js/model/unit'

function fetchByQuery(query: {
  period: Period['id']
  unit: Unit['id']
  layout: Layout['id']
  layoutCollection: LayoutCollection['id']
}) {
  return axios.get<UnitViewData>(Routing.generate('u2_layout_unitviewdata'), {
    params: {
      ...query,
    },
  })
}

function update(
  query: {
    period: Period['id']
    unit: Unit['id']
    layout: Layout['id']
    layoutCollection: LayoutCollection['id']
  },
  formData: FormData
) {
  return axios.post<{ messages: Record<string, Array<string>>; html: string }>(
    Routing.generate('u2_layout_unitviewsubmit', {
      ...query,
    }),
    formData
  )
}

export const unitViewDataApi = {
  fetchByQuery,
  update,
}
