import axios from 'axios'
import { acceptHMRUpdate, defineStore } from 'pinia'
import unionBy from 'lodash/unionBy'
import { ref } from 'vue'
import { fetchStates } from '@js/types'
import { useStatusStore } from '@js/stores/status'
import useHandleAxiosErrorResponse from '@js/composable/useHandleAxiosErrorResponse'
import type { FetchState, HydraCollectionResponse, NewApiResource } from '@js/types'
import type { CheckState } from '@js/model/checkstate'
import type { Task } from '@js/model/task'

export const useTaskStore = defineStore('task', () => {
  const checkLogsFetchState = ref<FetchState>(fetchStates.idle)
  const currentCheckStates = ref<Array<CheckState>>([])
  const task = ref<Task>()

  const statusStore = useStatusStore()
  async function fetchTaskById(taskId: Task['id']) {
    const { data } = await axios.get<Task>(`/api/tasks/${taskId}`)
    task.value = data
    await Promise.all([fetchCurrentCheckStates(taskId), statusStore.fetchStatusByIri(data.status)])
  }

  async function fetchCurrentCheckStates(taskId: Task['id']) {
    const { data } = await axios.get<HydraCollectionResponse<CheckState>>(
      `/api/tasks/${taskId}/check-states`,
      {
        params: {
          current: true,
          pagination: false,
        },
      }
    )
    currentCheckStates.value = data['hydra:member']
  }

  const { resolveNotification } = useHandleAxiosErrorResponse()

  async function changeCheckState(changedCheckState: NewApiResource<CheckState>) {
    try {
      const { data: newCheckState } = await axios.post<CheckState>(
        '/api/task-check-states',
        changedCheckState
      )

      currentCheckStates.value = unionBy([newCheckState], currentCheckStates.value, 'check')
    } catch (error) {
      resolveNotification(error)
    }
  }

  function refresh() {
    if (task.value) {
      return fetchTaskById(task.value.id)
    }
  }

  return {
    checkLogsFetchState,
    currentCheckStates,
    task,
    refresh,
    fetchTaskById,
    fetchCurrentCheckStates,
    changeCheckState,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskStore, import.meta.hot))
}
