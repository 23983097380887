import axios from 'axios'
import Routing from '@js/Routing'
import type { SystemMessage } from '@js/model/system_message'
import type { HydraCollectionResponse } from '@js/types'

const basePath = '/api/system-messages'
const activeBasePath = Routing.generate('u2_systemmessagedisplay_getmessages')

function deleteById(id: SystemMessage['id']) {
  return axios.delete<SystemMessage>(`/api/system-messages/${id}`)
}

function fetchActive() {
  return axios.get<Array<SystemMessage>>(activeBasePath)
}

function fetchAll() {
  return axios.get<HydraCollectionResponse<SystemMessage>>(basePath)
}

function fetchById(id: SystemMessage['id']) {
  return axios.get<SystemMessage>(`/api/system-messages/${id}`)
}

function fetchByQuery(query?: { page?: number; itemsPerPage?: number; pagination?: boolean }) {
  return axios.get<HydraCollectionResponse<SystemMessage>>(basePath, {
    params: query,
  })
}

function save(systemMessage: Partial<SystemMessage>) {
  if (systemMessage.id === undefined) {
    return axios.post<SystemMessage>(basePath, systemMessage)
  }
  return axios.put<SystemMessage>(`${basePath}/${systemMessage.id}`, systemMessage)
}

export const systemMessageApi = {
  deleteById,
  fetchActive,
  fetchAll,
  fetchById,
  fetchByQuery,
  save,
}
