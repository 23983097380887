import axios from 'axios'
import type { documentTemplateTypes } from '@js/model/document-template'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { DocumentTemplate } from '@js/model/document-template'
import type { BaseDocumentSection } from '@js/model/document'

const basePath = '/api/document-templates'

export type DocumentTemplateSectionContent = {
  '@type': 'DocumentTemplateSectionContent'
  '@id': string
  content: string
}

export type DocumentTemplateSection = BaseDocumentSection & {
  '@type': 'DocumentTemplateSection'
}

function fetchByQuery(query: {
  page?: number
  pagination?: boolean
  itemsPerPage?: number
  'sort[name]'?: SortingDirection
  'sort[type]'?: SortingDirection
  'sort[createdAt]'?: SortingDirection
  'sort[updatedAt]'?: SortingDirection
  'sort[createdBy.username]'?: SortingDirection
  'sort[updatedBy.username]'?: SortingDirection
}) {
  return axios.get<HydraCollectionResponse<DocumentTemplate>>(basePath, {
    params: { ...query },
  })
}

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<DocumentTemplate>(iri)
}

function deleteById(id: number) {
  return axios.delete<DocumentTemplate>(`${basePath}/${id}`)
}

function update(documentTemplate: Partial<DocumentTemplate>) {
  return axios.patch<DocumentTemplate>(`${basePath}/${documentTemplate.id}`, documentTemplate)
}

function fetchSections(id: DocumentTemplate['id']) {
  return axios.get<HydraCollectionResponse<DocumentTemplateSection>>(`${basePath}/${id}/sections`)
}

function fetchRenderedSections(id: DocumentTemplate['id']) {
  return axios.get<HydraCollectionResponse<DocumentTemplateSectionContent>>(
    `${basePath}/${id}/rendered-sections`
  )
}

function create(
  file: File,
  type: keyof typeof documentTemplateTypes,
  name: string,
  description: string | null
) {
  const requestData = new FormData()

  requestData.append('type', type)
  requestData.append('name', name)
  if (description) {
    requestData.append('description', description)
  }
  requestData.append('uploadedFile', file, file.name)

  return axios.post<DocumentTemplate>(basePath, requestData)
}

export const documentTemplateApi = {
  create,
  basePath,
  fetchByQuery,
  fetchById,
  fetchSections,
  fetchByIri,
  delete: deleteById,
  update,
  fetchRenderedSections,
}
