import { computed, toRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@js/query'
import type { MaybeRefOrGetter } from 'vue'
import type { User } from '@js/model/user'

export default function useDashboardsAllAssignedQuery(
  id: MaybeRefOrGetter<User['id'] | undefined>
) {
  const idRef = toRef(id as User['id'])
  const query = useQuery({
    ...queries.users.single(idRef)._ctx.dashboards,
    enabled: computed(() => !!idRef.value),
  })

  return {
    ...query,
    items: computed(() => query.data.value?.['hydra:member'] ?? []),
  }
}
