import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toRef, unref } from 'vue'
import { dashboardApi } from '@js/api/dashboardApi'
import { dashboardWidgetApi } from '@js/api/dashboardWidgetApi'
import type { MaybeRefOrGetter } from 'vue'
import type { Dashboard } from '@js/model/dashboard'

export const dashboards = createQueryKeys('dashboards', {
  all: null,
  upNext: {
    queryKey: null,
    queryFn: () => dashboardWidgetApi.fetchUpNext().then((response) => response.data),
  },
  currentWeekOverview: {
    queryKey: null,
    queryFn: () => dashboardWidgetApi.currentWeekOverview().then((response) => response.data),
  },
  single: (id: MaybeRefOrGetter<Dashboard['id']>) => {
    return {
      queryKey: [id],
      queryFn: () => dashboardApi.fetchById(unref(toRef(id))).then((response) => response.data),
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () =>
            dashboardApi.fetchAssignedUsers(unref(toRef(id))).then((response) => response.data),
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () =>
            dashboardApi
              .fetchInheritedAssignedUsers(unref(toRef(id)))
              .then((response) => response.data),
        },
        userGroups: {
          queryKey: null,
          queryFn: () =>
            dashboardApi
              .fetchAssignedUserGroups(unref(toRef(id)))
              .then((response) => response.data),
        },
      },
    }
  },
})
