import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/workflow/field-configuration/FieldConfigurationList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.field_configuration.plural'),
      },
    },
    name: 'FieldConfigurationList',
    path: '/workflow/field-configurations',
  },
] as Array<RouteRecordRaw>
