import axios from 'axios'
import Routing from '@js/Routing'
import type { LayoutCollection } from '@js/model/layoutCollection'
import type { GroupViewData, Layout } from '@js/model/layout'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { Period } from '@js/model/period'

type GroupViewDataQuery = {
  period: Period['id']
  unitHierarchy: UnitHierarchy['id']
  layout: Layout['id']
  layoutCollection: LayoutCollection['id']
}

function fetchByQuery(query: GroupViewDataQuery) {
  return axios.get<GroupViewData>(Routing.generate('u2_layout_groupviewdata'), {
    params: {
      ...query,
    },
  })
}

export const groupViewDataApi = {
  fetchByQuery,
}
