import { QueryClient } from '@tanstack/vue-query'
import isObject from 'lodash/isObject'
import axios from 'axios'
import { StatusCodes as HttpStatusCodes } from 'http-status-codes'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 15000,
      retry: (failureCount: number, error: unknown) => {
        if (isObject(error) && axios.isAxiosError(error)) {
          if (error.response && error.response.status >= HttpStatusCodes.INTERNAL_SERVER_ERROR)
            return failureCount < 2
        }
        return false
      },
    },
  },
})

export default queryClient
