import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import { useUserGroupStore } from '@js/stores/user-group'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/user/UserGroupList.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
      globalSearch: {
        icon: 'users',
        name: () => Translator.trans('u2_core.user_groups'),
      },
    },
    name: 'UserGroupList',
    path: '/user-group',
  },
  {
    component: () => import('@js/pages/user/UserGroupNew.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'UserGroupNew',
    path: '/user-group/new',
  },
  {
    component: () => import('@js/pages/user/UserGroupEdit.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'UserGroupEdit',
    path: '/user-group/:id/edit',
    props: (to: RouteLocation) => ({ id: to.params.id }),
    beforeEnter: async (to: RouteLocation) => {
      const userGroupStore = useUserGroupStore()
      await userGroupStore.fetchUserGroupById(+to.params.id)
    },
  },
] as Array<RouteRecordRaw>
