import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type MaybeRefOrGetter, toValue } from 'vue'
import invariant from 'tiny-invariant'
import { userGroupApi } from '@js/api/userGroupApi'
import type { UserGroup } from '@js/model/userGroup'

export const userGroups = createQueryKeys('userGroups', {
  all: {
    queryKey: null,
    queryFn: () => userGroupApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRefOrGetter<UserGroup['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = toValue(id)
        invariant(idValue)
        return userGroupApi.fetchById(idValue).then((response) => response.data)
      },
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return userGroupApi.fetchAssignedUsers(idValue).then((response) => response.data)
          },
        },
        roles: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return userGroupApi.fetchAssignedRoles(idValue).then((response) => response.data)
          },
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return userGroupApi.fetchInheritedRoles(idValue).then((response) => response.data)
          },
        },
        units: {
          queryKey: null,
          queryFn: () => {
            const idValue = toValue(id)
            invariant(idValue)
            return userGroupApi.fetchAssignedUnits(idValue).then((response) => response.data)
          },
        },
      },
    }
  },
})
