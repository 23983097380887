import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { taskTypeApi } from '@js/api/taskTypeApi'
import type { LocationQuery } from 'vue-router'
import type { TaskListInfo, TaskShortName } from '@js/model/task'

export const useTaskListInfoStore = defineStore('task-list-info', () => {
  const shortName = ref<string>()
  const taskListInfo = ref<TaskListInfo>()

  async function fetchTaskListInfo(taskShortName: TaskShortName, queryParameter: LocationQuery) {
    const { data } = await taskTypeApi.fetchTaskListInfo(taskShortName, queryParameter)
    shortName.value = taskShortName
    taskListInfo.value = data
  }

  return {
    shortName,
    taskListInfo,
    fetchTaskListInfo,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTaskListInfoStore, import.meta.hot))
}
