import { z } from 'zod'
import type { MessageBag, Notification } from '@js/stores/notifications'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { Icon } from '@js/utilities/name-lists'
import type { AxiosResponse } from 'axios'
import type { RouteLocation } from 'vue-router'

/**
 * The PartialBy helper is a utility type that takes an object type and makes the specified properties optional.
 *
 * @example
 * interface Thing {
 *     id: string;
 *     text: string;
 *     creationDate: string;
 * }
 *
 * let thing: PartialBy<Thing, 'id | 'text'>
 * // {
 * //     id?
 * //     text?
 * //     creationDate
 * // }
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

/**
 * The Prettify helper is a utility type that takes an object type and makes
 * the hover overlay more readable.
 *
 * @example
 * type Intersected = Prettify<{ a: string } & { b: number } & { c: boolean }>
 *
 * becomes:
 * {
 *   a: string;
 *   b: number;
 *   c: boolean;
 * }
 *
 * @see https://www.totaltypescript.com/concepts/the-prettify-helper
 */
export type Prettify<T> = { [K in keyof T]: T[K] } & unknown

/**
 * The StringWithAutocompleteOptions helper is a utility type that allows
 * any string but takes predefined options to autocomplete.
 */
export type StringWithAutocompleteOptions<TOptions extends string> = (string & {}) | TOptions

export const apiResourceIdSchema = z.string()
export const apiResourceSchema = z.object({
  '@context': z.string().optional(),
  '@id': apiResourceIdSchema,
  '@type': z.string().optional(),
})

export type ApiResourceId = z.infer<typeof apiResourceIdSchema>

export type ApiResource = z.infer<typeof apiResourceSchema>

export type NewApiResource<Type extends ApiResource> = PartialBy<Type, keyof ApiResource>

export type HydraCollectionResponse<T extends ApiResource> = {
  '@id': ApiResourceId
  'hydra:totalItems': number
  'hydra:member': Array<T>
  'hydra:view': {
    '@id': string
    '@type': string
    'hydra:first': string
    'hydra:last': string
    'hydra:next': string
  }
  'hydra:search': {
    '@type': string
    'hydra:template': string
    'hydra:variableRepresentation': string
    'hydra:mapping': [
      {
        '@type': string
        variable: string
        property: string
        required: boolean
      },
    ]
  }
}

export type ApiViolation = {
  propertyPath: string
  message: string
}

export interface HydraErrorResponse extends AxiosResponse {
  data: {
    'hydra:title': string
    'hydra:description': string
    violations?: Array<ApiViolation>
  }
}

export interface HtmlFormResponse extends AxiosResponse {
  data: {
    html: string
    messages: Array<Notification>
    disabled: boolean
  }
}

export interface HtmlFormCreatedResponse extends AxiosResponse {
  data: {
    redirect: string
    messages: MessageBag
    disabled: boolean
  }
}

export const fetchStates = {
  error: 'ERROR',
  loading: 'LOADING',
  idle: 'IDLE',
  resolved: 'RESOLVED',
} as const

export type FetchState = (typeof fetchStates)[keyof typeof fetchStates]

export const editorStates = {
  preparing: 'PREPARING',
  ready: 'READY',
  saving: 'SAVING',
} as const

export type EditorState = (typeof editorStates)[keyof typeof editorStates]

export type TreeNode<T = Record<string, unknown>> = {
  [K in keyof T]: T[K]
} & {
  id: number
  label: string
  icon?: Icon
  isCollapsed?: boolean
  isSelected?: boolean
  children: Array<TreeNode<T>>
}

export type Tree<T = Record<string, unknown>> = Array<TreeNode<T>>

export const tableContentTypes = [
  'date',
  'datetime',
  'list',
  'boolean',
  'user',
  'count',
  // The following are from the backend
  'id',
  'period',
  'currency',
  'money',
  'money_full',
  'number',
  'percentage',
  'workflow_status',
] as const

export type TableHeader = {
  id: string
  align?: 'left' | 'center' | 'right'
  filter?: boolean
  hidden?: boolean
  isSortable?: boolean
  label?: string
  name?: string
  required?: boolean
  selectedByDefault?: boolean
  type?: StringWithAutocompleteOptions<(typeof tableContentTypes)[number]>
  unit?: string
  wrap?: boolean
}

type TableQueryFilter = {
  search?: string
  [key: TableHeader['id']]: string | boolean | number | undefined
}

export type TableQuery = {
  page: number
  itemsPerPage?: number
  maxVisiblePages?: number
  selectedColumns?: Array<string>
  sort?: Record<string, SortingDirection>
  filter: TableQueryFilter
  pagination?: boolean
}

export type SelectOption = {
  id: string | number
  name: string
  disabled?: boolean
  closed?: boolean
  type?: string
  icon?: Icon
}

export type FlatSelectOption<T = SelectOption> = T extends SelectOption
  ? T & {
      level: number
    }
  : never

export type SelectOptionGroup<TOption extends SelectOption = SelectOption> = {
  label: string
  options: Array<TOption>
}

export type SelectOptions<TOption extends SelectOption = SelectOption> = Array<
  TOption | SelectOptionGroup<TOption>
>

export type FlatSelectOptionGroup = Omit<SelectOptionGroup, 'options'> & {
  label: string
  level: number
}

export function isSelectOptionGroup(
  option: SelectOption | SelectOptionGroup | FlatSelectOption | FlatSelectOptionGroup
): option is SelectOptionGroup {
  return 'label' in option
}

export function isSelectOption(option: unknown): option is SelectOption {
  return (
    option !== null &&
    typeof option === 'object' &&
    'name' in option &&
    typeof option.name === 'string'
  )
}

export type BreadcrumbOption = {
  name: string
  current?: boolean
  to: RouteLocation
}

export type BreadcrumbOptionGroup = {
  label: string
  options: Array<BreadcrumbOption>
}

export type Replace<
  T extends string,
  S extends string,
  D extends string,
  A extends string = '',
> = T extends `${infer L}${S}${infer R}` ? Replace<R, S, D, `${A}${L}${D}`> : `${A}${T}`
