import { fileTypeApi } from '@js/api/fileTypeApi'
import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/configuration/FileTypeList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.file_type.plural'),
      },
    },
    name: 'FileTypeList',
    path: '/configuration/file-type',
  },
  {
    component: () => import('@js/pages/configuration/FileTypeNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'FileTypeNew',
    path: '/configuration/file-type/new',
  },
  {
    component: () => import('@js/pages/configuration/FileTypeEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2.file_type')} #${params.id}`
      },
    },
    name: 'FileTypeEdit',
    path: '/configuration/file-type/:id/edit',
    props: (to: RouteLocation) => ({
      fileType: to.meta.fileType,
    }),
    beforeEnter: async (to: RouteLocation) => {
      const { data: item } = await fileTypeApi.fetchById(Number(to.params.id))
      to.meta.fileType = item
    },
  },
] as Array<RouteRecordRaw>
