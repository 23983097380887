import axios from 'axios'
import Routing from '@js/Routing'
import type { CalendarEntry } from '@js/model/calendar'

export const dashboardWidgetApi = {
  fetchUpNext: () => {
    return axios.get<Record<string, Array<CalendarEntry>>>(Routing.generate('u2_calendar_upnext'))
  },
  currentWeekOverview: () => {
    return axios.get<{ entries: Record<string, Array<CalendarEntry>> }>(
      Routing.generate('u2_calendar_currentweek')
    )
  },
}
