import BaseLayout from '@js/layouts/BaseLayout.vue'
import type { RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/support/SupportEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: BaseLayout,
    },
    name: 'SupportEdit',
    path: '/support/info/edit',
  },
  {
    component: () => import('@js/pages/support/SupportInfo.vue'),
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'SupportInfo',
    path: '/support/info',
  },
  {
    component: () => import('@js/pages/support/SupportClientInfo.vue'),
    meta: {
      auth: false,
      layout: BaseLayout,
    },
    name: 'SupportClientInfo',
    path: '/support/client-info',
  },
] as Array<RouteRecordRaw>
