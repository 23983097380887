import axios, { type AxiosResponse } from 'axios'
import type { Condition } from '@js/api/transitionConditionApi'
import type { Action } from '@js/api/transitionActionApi'
import type { Status } from '@js/model/status'
import type { ApiResource, ApiResourceId } from '@js/types'

export type Transition = ApiResource & {
  id: number
  name: string
  conditions: Array<Condition>
  actions: Array<Action>
  description: string
  originStatus: Status['@id']
  destinationStatus: Status['@id']
  workflow: ApiResourceId
}

export type TransitionWithEmbeddedStatus = Omit<
  Transition,
  'originStatus' | 'destinationStatus'
> & {
  originStatus: Status
  destinationStatus: Status
}

const basePath = '/api/transitions'

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<TransitionWithEmbeddedStatus>(iri)
}

function save(transition: Partial<Transition>) {
  if (transition.id === undefined) {
    return axios.post<Transition, AxiosResponse<TransitionWithEmbeddedStatus>>(basePath, transition)
  }
  return axios.put<Transition, AxiosResponse<TransitionWithEmbeddedStatus>>(
    `${basePath}/${transition.id}`,
    transition
  )
}

export const workflowTransitionApi = {
  fetchById,
  fetchByIri,
  save,
}
