import axios from 'axios'
import type { HydraCollectionResponse } from '@js/types'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

const basePath = '/api/authorizations'

function fetchAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

function updateAssignedUserGroups(id: number, updatedGroups: Array<NonNullable<UserGroup['@id']>>) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

function fetchAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

function fetchInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

export const authorizationApi = {
  fetchAssignedUserGroups,
  fetchAssignedUsers,
  updateAssignedUserGroups,
  updateAssignedUsers,
  fetchInheritedAssignedUsers,
}
