import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/user/AuthorizationEdit.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'AuthorizationEdit',
    path: '/authorisation/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/user/AuthorizationNew.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'AuthorizationNew',
    path: '/authorisation/new',
  },
  {
    component: () => import('@js/pages/user/AuthorizationProfileEdit.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'AuthorizationProfileEdit',
    path: '/authorisation/profile/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/user/AuthorizationProfileNew.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
    },
    name: 'AuthorizationProfileNew',
    path: '/authorisation/profile/new',
  },
  {
    component: () => import('@js/pages/user/AuthorizationList.vue'),
    meta: {
      auth: 'ROLE_USER_GROUP_ADMIN',
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.authorisation.authorisation'),
      },
    },
    name: 'AuthorizationList',
    path: '/authorisation',
  },
] as Array<RouteRecordRaw>
