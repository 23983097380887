import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/configuration/TaxAccountingItemList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.item.plural'),
      },
    },
    name: 'TaxAccountingItemList',
    path: '/configuration/item',
  },
  {
    component: () => import('@js/pages/configuration/ItemEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'ItemEdit',
    path: '/configuration/item/:id/edit',
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    component: () => import('@js/pages/configuration/ItemNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'ItemNew',
    path: '/configuration/item/new',
  },
  {
    component: () => import('@js/pages/layout/TaxAccountingLayoutList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.datasheets.datasheet.plural'),
      },
    },
    name: 'TaxAccountingLayoutList',
    path: '/configuration/layout',
  },
  {
    component: () => import('@js/pages/layout/LayoutEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'LayoutEdit',
    path: '/configuration/layout/:id/edit',
    props: (route: RouteLocation) => ({
      id: +route.params.id,
    }),
  },
  {
    component: () => import('@js/pages/layout/LayoutNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'LayoutNew',
    path: '/configuration/layout/new',
  },
] as Array<RouteRecordRaw>
