import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { queries } from '@js/query'

export default function useLayoutCollectionsAllQuery(options?: { enabled: boolean }) {
  const query = useQuery({
    ...queries.layoutCollections.all,
    ...options,
  })

  return {
    ...query,
    items: computed(() => query.data.value?.data['hydra:member'] ?? []),
  }
}
