import axios from 'axios'
import type { ItemUnitHierarchyValue, LayoutItem } from '@js/model/layout'
import type { UnitHierarchy } from '@js/model/unit_hierarchy'
import type { Period } from '@js/model/period'
import type { MaybeRef } from '@vueuse/core'

const basePath = '/api/item-unit-hierarchy-values'

function buildId(
  itemId: MaybeRef<LayoutItem['id']>,
  unitHierarchyId: MaybeRef<UnitHierarchy['id']>,
  periodId: MaybeRef<Period['id']>
) {
  return `${itemId}-${unitHierarchyId}-${periodId}`
}

export const itemUnitHierarchyValueApi = {
  fetchById: function (
    itemId: MaybeRef<LayoutItem['id']>,
    unitHierarchyId: MaybeRef<UnitHierarchy['id']>,
    periodId: MaybeRef<Period['id']>
  ) {
    return axios.get<ItemUnitHierarchyValue>(
      `${basePath}/${buildId(itemId, unitHierarchyId, periodId)}`
    )
  },
  basePath,
  buildId,
}
