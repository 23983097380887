import type { AuthorizationString } from '@js/model/authorization'
import type { Address } from '@js/model/address'
import type { Role } from '@js/model/role'
import type { Locale } from '@js/stores/user-settings'
import type { ApiResource, ApiResourceId } from '@js/types'

export type ContactWithAllProperties = ApiResource & {
  id: number
  nameFirst: string | null
  nameLast: string
  email: string
  title: string | null
  company: string | null
  country: ApiResourceId | null
  telephone: string | null
  mobile: string | null
  fax: string | null
  address: Address | null
}

export type ContactWithRestrictedProperties = Omit<
  ContactWithAllProperties,
  'title' | 'company' | 'country' | 'telephone' | 'mobile' | 'fax' | 'address'
>

export type Contact = ContactWithAllProperties | ContactWithRestrictedProperties

export type UserWithAllProperties = ApiResource & {
  id: number
  username: string
  accountExpires: string
  authorizations: Array<AuthorizationString>
  contact: ContactWithAllProperties
  groups: Array<ApiResourceId>
  lastActivity: string | null
  lastLogin: string | null
  locked: boolean
  parentUser: string
  passwordExpires: string
  roles: Array<Role> // Direct and inherited roles
  userRoles: Array<Role> // Direct roles only
}

export type UserWithRestrictedProperties = Omit<
  UserWithAllProperties,
  | 'accountExpires'
  | 'authorizations'
  | 'groups'
  | 'lastActivity'
  | 'lastLogin'
  | 'locked'
  | 'parentUser'
  | 'passwordExpires'
  | 'roles'
  | 'userRoles'
  | 'contact'
> & {
  contact: ContactWithRestrictedProperties
}

export type User = UserWithAllProperties | UserWithRestrictedProperties

export type UserSettings = {
  '@id': ApiResourceId
  applicationLanguage: Locale
  csvImportDelimiter: string
  twoFactorEnabled: boolean
}

export function isUserWithAllProperties(entity: ApiResource): entity is UserWithAllProperties {
  return 'locked' in entity
}
