import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { attachmentApi } from '@js/api/attachmentApi'
import type { ApiResourceId } from '@js/types'
import type { MaybeRef } from '@vueuse/core'

export const attachments = createQueryKeys('attachments', {
  all: (iri: MaybeRef<ApiResourceId>) => {
    return {
      queryKey: [iri],
      queryFn: () =>
        attachmentApi.fetchAll(unref(iri)).then((response) => response.data['hydra:member'] ?? []),
    }
  },
})
