import { createQueryKeys } from '@lukemorales/query-key-factory'
import { toValue } from 'vue'
import invariant from 'tiny-invariant'
import { periodApi } from '@js/api/periodApi'
import type { Period } from '@js/model/period'
import type { MaybeRef } from '@vueuse/core'

export const periods = createQueryKeys('periods', {
  all: {
    queryKey: null,
    queryFn: () => periodApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<Period['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const value = toValue(id)
        invariant(value !== undefined, 'id is required')
        return periodApi.fetchById(value).then((response) => response.data)
      },
    }
  },
})
