<template>
  <PageLoader />
  <component :is="route.meta.layout || 'div'">
    <RouterView v-slot="{ Component }">
      <Suspense v-if="Component" :key="route.path" timeout="0" @resolve="onResolve">
        <template #default>
          <component :is="Component" />
        </template>
      </Suspense>
    </RouterView>
  </component>
  <PageLoaderProgressBar />
</template>

<script lang="ts" setup>
import { useHead } from '@vueuse/head'
import { useRoute, useRouter } from 'vue-router'
import { usePageStore } from '@js/stores/page'
import PageLoaderProgressBar from '@js/components/loader/PageLoaderProgressBar.vue'
import PageLoader from '@js/components/loader/PageLoader.vue'
import { useLoadingIndicatorStore } from '@js/stores/loading-indicator'

defineProps<{
  messages?: object
}>()

useHead({
  titleTemplate: (title) => (title ? title + ' | Universal Units' : 'Universal Units'),
})

const router = useRouter()
const route = useRoute()
const pageStore = usePageStore()
pageStore.loading = true
router.isReady().finally(() => (pageStore.loading = false))

const loadingIndicatorStore = useLoadingIndicatorStore()

const onResolve = () => {
  loadingIndicatorStore.finishLoadingIndicator()
}
</script>
