const Url = {
  addOrUpdateQueryParameter(
    parameter: string,
    value: string | number | null = null,
    addChangeToHistory = false
  ) {
    const removeRegex = new RegExp('([?&])' + parameter + '=[^&;]+[&;]?')
    const updateRegex = new RegExp('([?&])' + parameter + '[^&]*')
    const urlQueryString = window.location.search
    const parameterWithValue = `${parameter}=${encodeURIComponent(value || '')}`
    let parameters = '?' + parameterWithValue

    if (urlQueryString) {
      // The url contains query parameters
      if (value === null) {
        // Empty value given, remove parameter
        parameters = urlQueryString.replace(removeRegex, '$1')
        parameters = parameters.replace(/[&;]$/, '')
      } else if (urlQueryString.match(updateRegex) !== null) {
        // Parameter exists, update it
        parameters = urlQueryString.replace(updateRegex, '$1' + parameterWithValue)
      } else {
        // Add a new parameter
        parameters = urlQueryString + '&' + parameterWithValue
      }
    }

    if (addChangeToHistory) {
      window.history.pushState({}, '', window.location.pathname + parameters)
      return
    }

    window.history.replaceState({}, '', window.location.pathname + parameters)
  },

  buildQueryStringFromObject(queryStringObject: Record<string, string | null>) {
    const queryStringParts = []

    for (const item in queryStringObject) {
      let itemValue = queryStringObject[item]
      if (itemValue === null) {
        continue
      }
      itemValue = encodeURIComponent(itemValue)
      queryStringParts.push(`${item}=${itemValue}`)
    }

    return queryStringParts.join('&')
  },

  getQueryParametersObject() {
    const queryParametersObject = {} as Record<string, string>
    if (window.location.search && window.location.search.length) {
      const queryStringItems = window.location.search.substring(1).split('&')
      queryStringItems.forEach((item) => {
        const [key, value] = item.split('=')
        queryParametersObject[key] = decodeURIComponent(value)
      })
    }

    return queryParametersObject
  },

  updateHash(hash: string, addChangeToHistory = true) {
    if (addChangeToHistory) {
      window.history.pushState({}, '', hash)
      return
    }

    window.history.replaceState({}, '', hash)
  },

  resolveRelativePath(redirectUrl: string) {
    // Urls containing a protocol are absolute
    if (redirectUrl.includes('http')) {
      const url = new URL(redirectUrl)

      // Remove the origin from the url to retrieve the relative path
      return url.href.replace(url.origin, '')
    }

    return redirectUrl
  },
}

export default Url
