import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/period/PeriodEdit.vue'),
    meta: {
      auth: 'PERIOD:WRITE',
      layout: BaseLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.period')} #${params.id}`
      },
    },
    name: 'PeriodEdit',
    path: '/period/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/period/PeriodNew.vue'),
    meta: {
      auth: 'PERIOD:WRITE',
      layout: BaseLayout,
    },
    name: 'PeriodNew',
    path: '/period/new',
  },
  {
    component: () => import('@js/pages/period/PeriodList.vue'),
    meta: {
      layout: BaseLayout,
      globalSearch: {
        icon: 'list',
        name: () => Translator.trans('u2.periods'),
      },
    },
    name: 'PeriodList',
    path: '/period',
  },
  {
    component: () => import('@js/pages/exchange-rate/ExchangeRateEdit.vue'),
    meta: {
      auth: 'PERIOD:WRITE',
      layout: BaseLayout,
      title: (params: { id: number }) => {
        return `${Translator.trans('u2_core.exchange_rate')} #${params.id}`
      },
    },
    name: 'ExchangeRateEdit',
    path: '/period/:periodId/exchange-rate/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id, periodId: +route.params.periodId }),
  },
  {
    component: () => import('@js/pages/exchange-rate/ExchangeRateNew.vue'),
    meta: {
      auth: 'PERIOD:WRITE',
      layout: BaseLayout,
    },
    name: 'ExchangeRateNew',
    path: '/period/:periodId/exchange-rate/new',
    props: (route: RouteLocation) => ({ periodId: +route.params.periodId }),
  },
] as Array<RouteRecordRaw>
