import axios from 'axios'
import type { ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { SavedFilter, SavedFilterSubscription } from '@js/model/saved-filter'
import type { User } from '@js/model/user'
import type { UserGroup } from '@js/model/userGroup'

export const basePath = '/api/saved-filter-subscriptions'

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<SavedFilterSubscription>(iri)
}

function fetchAll() {
  return fetchByQuery({ pagination: false })
}

function fetchByQuery(query?: {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  search?: string | Array<string>
}) {
  return axios.get<HydraCollectionResponse<SavedFilterSubscription>>(basePath, {
    params: { ...query },
  })
}

function create(payload: SavedFilter) {
  return axios.post<SavedFilter>(basePath, payload)
}

function update(id: number, payload: SavedFilter) {
  return axios.put<SavedFilter>(`${basePath}/${id}`, payload)
}

function remove(id: number) {
  return axios.delete<SavedFilter>(`${basePath}/${id}`)
}

function fetchAssignedUserGroups(id: number) {
  return axios.get<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    params: { pagination: false },
  })
}

function updateAssignedUserGroups(id: number, updatedGroups: Array<NonNullable<UserGroup['@id']>>) {
  return axios.put<HydraCollectionResponse<UserGroup>>(`${basePath}/${id}/groups`, {
    groups: updatedGroups,
  })
}

function fetchAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    params: { pagination: false },
  })
}

function updateAssignedUsers(id: number, updatedUsers: Array<NonNullable<User['@id']>>) {
  return axios.put<HydraCollectionResponse<User>>(`${basePath}/${id}/direct-users`, {
    directUsers: updatedUsers,
  })
}

function fetchInheritedAssignedUsers(id: number) {
  return axios.get<HydraCollectionResponse<User>>(`${basePath}/${id}/inherited-users`, {
    params: { pagination: false },
  })
}

export const savedFilterSubscriptionApi = {
  remove,
  create,
  update,
  fetchById,
  fetchByIri,
  fetchAll,
  fetchAssignedUserGroups,
  fetchAssignedUsers,
  updateAssignedUserGroups,
  updateAssignedUsers,
  fetchInheritedAssignedUsers,
}
