import { queries } from '@js/query'
// TODO: use `import { useQueryClient } from '@tanstack/vue-query'`
import queryClient from '@js/queryClient'
import BaseLayout from '@js/layouts/BaseLayout.vue'
import Translator from '@js/translator'
import { unitHierarchyApi } from '@js/api/unitHierarchyApi'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/unit/HierarchyList.vue'),
    meta: {
      auth: 'UNIT:MANAGE',
      layout: BaseLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2.unit_hierarchies'),
      },
    },
    name: 'HierarchyList',
    path: '/unit/hierarchy',
  },
  {
    component: () => import('@js/pages/unit/HierarchyEdit.vue'),
    meta: {
      auth: 'UNIT:MANAGE',
      layout: BaseLayout,
    },
    name: 'HierarchyEdit',
    path: '/unit/hierarchy/:id/edit-structure/:date',
    props: (to: RouteLocation) => {
      const [year, month, day] = to.params.date.toString().split('-')
      return {
        id: +to.params.id,
        date: new Date(+year, +month - 1, +day),
      }
    },
    beforeEnter: async (to: RouteLocation) => {
      if (!/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(to.params.date as string)) {
        return {
          name: 'Error404',
          params: { pathMatch: to.path.substring(1).split('/') },
          query: to.query,
          hash: to.hash,
        }
      }
      await Promise.all([
        queryClient.ensureQueryData({
          ...queries.unitHierarchies.single(+to.params.id),
        }),
        queryClient.fetchQuery({
          ...queries.unitHierarchies.single(+to.params.id)._ctx.structure(to.params.date as string),
        }),
        queryClient.prefetchQuery({
          ...queries.units.all,
        }),
      ])
    },
  },
  {
    component: () => import('@js/pages/unit/HierarchyAttributesEdit.vue'),
    meta: {
      auth: 'UNIT:MANAGE',
      layout: BaseLayout,
    },
    name: 'HierarchyAttributesEdit',
    path: '/unit/hierarchy/:id/edit-attributes',
    props: (to: RouteLocation) => ({
      unitHierarchy: to.meta.props?.unitHierarchy,
    }),
    beforeEnter: async (to: RouteLocation) => {
      to.meta.props = {
        unitHierarchy: (await unitHierarchyApi.fetchById(Number(to.params.id))).data,
      }
    },
  },
  {
    component: () => import('@js/pages/unit/HierarchyNew.vue'),
    meta: {
      auth: 'UNIT:MANAGE',
      layout: BaseLayout,
    },
    name: 'HierarchyNew',
    path: '/unit/hierarchy/new',
  },
] as Array<RouteRecordRaw>
