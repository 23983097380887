import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import invariant from 'tiny-invariant'
import { userApi } from '@js/api/userApi'
import type { MaybeRef } from '@vueuse/core'
import type { User } from '@js/model/user'

export const users = createQueryKeys('users', {
  all: {
    queryKey: null,
    queryFn: () => userApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<User['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const idValue = unref(id)
        invariant(idValue)
        return userApi.fetchById(idValue).then((response) => response.data)
      },
      contextQueries: {
        userGroups: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchAssignedUserGroups(idValue).then((response) => response.data)
          },
        },
        roles: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchAssignedRoles(idValue).then((response) => response.data)
          },
        },
        units: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchAssignedUnits(idValue).then((response) => response.data)
          },
        },
        inheritedRoles: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchInheritedRoles(idValue).then((response) => response.data)
          },
        },
        inheritedUnits: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchInheritedUnits(idValue).then((response) => response.data)
          },
        },
        dashboards: {
          queryKey: null,
          queryFn: () => {
            const idValue = unref(id)
            invariant(idValue)
            return userApi.fetchAssignedDashboards(idValue).then((response) => response.data)
          },
        },
      },
    }
  },
})
