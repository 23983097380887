import { createQueryKeys } from '@lukemorales/query-key-factory'
import invariant from 'tiny-invariant'
import { type MaybeRefOrGetter, toValue } from 'vue'
import { unitApi } from '@js/api/unitApi'
import type { UnitCollectionQuery } from '@js/api/unitApi'
import type { Unit } from '@js/model/unit'

export const units = createQueryKeys('units', {
  all: {
    queryKey: null,
    queryFn: () => unitApi.fetchAll().then((response) => response.data),
  },
  list: (filters?: MaybeRefOrGetter<UnitCollectionQuery>) => ({
    queryKey: [filters],
    queryFn: () => unitApi.fetchByQuery({ ...toValue(filters) }).then((response) => response.data),
  }),
  single: (id: MaybeRefOrGetter<Unit['id'] | undefined>) => {
    return {
      queryKey: [id],
      queryFn: () => {
        const value = toValue(id)
        invariant(value)
        return unitApi.fetchById(value).then((response) => response.data)
      },
      contextQueries: {
        directUsers: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return unitApi.fetchAssignedUsers(value).then((response) => response.data)
          },
        },
        inheritedUsers: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return unitApi.fetchInheritedAssignedUsers(value).then((response) => response.data)
          },
        },
        userGroups: {
          queryKey: null,
          queryFn: () => {
            const value = toValue(id)
            invariant(value)
            return unitApi.fetchAssignedUserGroups(value).then((response) => response.data)
          },
        },
      },
    }
  },
})
