import { defineAsyncComponent, defineComponent } from 'vue'
import { useNotificationsStore } from '@js/stores/notifications'
import type {
  AsyncComponentLoader,
  AsyncComponentOptions,
  Component,
  ComponentPublicInstance,
} from 'vue'

function useAsyncComponent() {
  const notificationStore = useNotificationsStore()

  function defineAsyncComponentWithFallback<
    T extends Component = new () => ComponentPublicInstance,
  >(source: AsyncComponentLoader<T> | AsyncComponentOptions<T>): T {
    const defaultOptions = {
      errorComponent: defineComponent({ template: '<span>·</span>' }),
      onError(error, retry, fail, attempts) {
        if (
          error.message.includes('error loading dynamically imported module') ||
          error.message.includes('Failed to fetch dynamically imported module')
        ) {
          if (attempts <= 2) {
            retry()
            return
          }
          notificationStore.addWarning(
            'A newer version of the application is available. Please refresh the page.'
          )
        }
        fail()
      },
    } satisfies Omit<AsyncComponentOptions<T>, 'loader'>

    if (typeof source === 'function') {
      return defineAsyncComponent({ ...defaultOptions, loader: source })
    }

    return defineAsyncComponent({ ...defaultOptions, ...source })
  }

  return { defineAsyncComponentWithFallback }
}

export { useAsyncComponent }
