<template>
  <div
    role="progressbar"
    :aria-valuenow="loadingIndicatorProgress"
    :class="[
      'animate-gradient pointer-events-none fixed left-0 right-0 top-0 z-40 h-1 w-full origin-left bg-gradient-to-r from-u2 from-0% via-red-400 via-50% to-u2 to-100% shadow-sm shadow-white/30',
      { 'opacity-0': !showLoadingIndicator },
      { 'loader-is-showing': showLoadingIndicator },
    ]"
    :style="`transform: scaleX(${loadingIndicatorProgress}%);transition: transform 0.4s, opacity 0.4s;`"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount } from 'vue'
import { useLoadingIndicatorStore } from '@js/stores/loading-indicator'

const loadingIndicatorStore = useLoadingIndicatorStore()
const showLoadingIndicator = computed(() => loadingIndicatorStore.showLoadingIndicator)
const loadingIndicatorProgress = computed(() => loadingIndicatorStore.loadingIndicatorProgress)

onBeforeUnmount(() => {
  loadingIndicatorStore.clearLoadingIndicator()
})
</script>

<style scoped>
.animate-gradient {
  animation: gradient 2s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  background-position: 0 100%;
  background-size: 200% auto;
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -200% 0;
  }
}
</style>
