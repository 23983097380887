import axios from 'axios'
import type { Status } from '@js/model/status'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'
import type { HydraCollectionResponse } from '@js/types'
import type { WorkflowBinding } from '@js/api/workflowApi'

const basePath = '/api/workflow-bindings'
type WorkflowBindingCollectionQuery = {
  page?: number
  pagination?: boolean
  itemsPerPage?: number
  'sort[workflow.name]'?: SortingDirection
  search?: string | Array<string>
}
export type StatusMapping = {
  source: Status['@id']
  destination: Status['@id']
}
type WorkflowBindingPatchPayload = {
  binding: WorkflowBinding['@id']
  targetWorkflow: WorkflowBinding['workflow']
  statusMappings: Array<StatusMapping>
}

function fetchByQuery(query: WorkflowBindingCollectionQuery) {
  return axios.get<HydraCollectionResponse<WorkflowBinding>>(basePath, {
    params: { ...query },
  })
}

function fetchByBindingId(bindingId: WorkflowBinding['bindingId']) {
  return fetchByIri(`${basePath}/${bindingId}`)
}

function fetchByIri(iri: WorkflowBinding['@id']) {
  return axios.get<WorkflowBinding>(iri)
}

function update(payload: WorkflowBindingPatchPayload) {
  return axios.patch<WorkflowBinding>(`${payload.binding}`, payload)
}

export const workflowBindingApi = {
  fetchByBindingId,
  fetchByIri,
  fetchByQuery,
  update,
}
