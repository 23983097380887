import { syncRef, useLocalStorage } from '@vueuse/core'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer)

  return `${scrollbarWidth}px`
}
export const usePageStore = defineStore('page', () => {
  const offCanvas = ref(false)

  const loading = ref(false)

  const sidebarCollapsedLocal = useLocalStorage('sidebar_collapsed', false)
  const sidebarCollapsed = ref(sidebarCollapsedLocal.value)
  syncRef(sidebarCollapsed, sidebarCollapsedLocal, { direction: 'ltr' })

  const sidebarWidthLocal = useLocalStorage('sidebar_width', 220)
  const sidebarWidth = ref(sidebarWidthLocal.value)
  syncRef(sidebarWidth, sidebarWidthLocal, { direction: 'ltr' })

  const scrollbarWidth = ref(getScrollbarWidth())

  return {
    loading,
    offCanvas,
    scrollbarWidth,
    sidebarCollapsed,
    sidebarWidth,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePageStore, import.meta.hot))
}
