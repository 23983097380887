import * as Sentry from '@sentry/browser'
import { nextTick } from 'vue'
import { useNotificationsStore } from '@js/stores/notifications'
import Translator from '@js/translator'
import type { RouteLocationGeneric } from 'vue-router'

function resolveRouteName(code?: number) {
  switch (code) {
    case 403:
      return 'Error403'
    case 404:
      return 'Error404'
    case 503:
      return 'Error503'
    default:
      return 'Error'
  }
}

export function createErrorLocation(to: RouteLocationGeneric, code?: number) {
  return {
    name: resolveRouteName(code),
    params: {
      pathMatch: to.path.substring(1).split('/'),
    },
    query: to.query,
    hash: to.hash,
  }
}

export function logDeprecatedRouteUsage(to: RouteLocationGeneric) {
  /*
   * Wrap captureException in a next tick to ensure it runs after the current event loop before finishing the redirect.
   * Somehow using captureException in redirect cause the error to be not logged to sentry.
   *
   * @todo Research why nextTick/setTimeout is needed in order to let sentry send the event successfully
   */
  nextTick(() => {
    Sentry.captureException(new Error(`Deprecated route "${to.fullPath}" used`))
  })

  useNotificationsStore().addNotice(Translator.trans('u2.route.deprecated'))
}
