import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { countryApi } from '@js/api/countryApi'
import type { Country } from '@js/model/country'
import type { MaybeRef } from '@vueuse/core'

export const countries = createQueryKeys('countries', {
  all: {
    queryKey: null,
    queryFn: () => countryApi.fetchAll().then((response) => response.data),
  },
  single: (id: MaybeRef<Country['id']>) => ({
    queryKey: [id],
    queryFn: () => countryApi.fetchById(unref(id)).then((response) => response.data),
  }),
})
