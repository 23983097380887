import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

const hideTimeoutInMilliseconds = 400
const hideTimerTimeoutInMilliseconds = 500

const minProgress = 0
const maxAllowedProgress = 90
const maxProgress = 100
const loadingProgressTimeoutInMilliseconds = 200

const throttleTimeoutInMilliseconds = 200
const stepDuration = 5

export const useLoadingIndicatorStore = defineStore('loading-indicator', () => {
  const isLoading = ref(false)
  const showLoadingIndicator = ref(false)
  const loadingIndicatorProgress = ref(0)

  const loadingIndicatorProgressInterval = ref<NodeJS.Timeout>()
  const hideTimer = ref<NodeJS.Timeout>()
  const throttleTimer = ref<NodeJS.Timeout>()

  function clearLoadingIndicator() {
    clearTimeout(hideTimer.value)

    clearInterval(loadingIndicatorProgressInterval.value)
    loadingIndicatorProgressInterval.value = undefined

    clearTimeout(throttleTimer.value)
    throttleTimer.value = undefined
  }
  function hideLoadingIndicator() {
    clearLoadingIndicator()

    isLoading.value = false

    // The nested timer is needed for the visual effect of loader going all the way to the end when finish function is called and then disappear after a short pause.
    // If we use one setTimeout the loader doesn't pause, and we'll see the progress decreasing
    hideTimer.value = setTimeout(() => {
      showLoadingIndicator.value = false
      setTimeout(() => {
        loadingIndicatorProgress.value = minProgress
      }, hideTimeoutInMilliseconds)
    }, hideTimerTimeoutInMilliseconds)
  }
  function startTimer() {
    loadingIndicatorProgressInterval.value = setInterval(() => {
      loadingIndicatorProgress.value = Math.min(
        maxAllowedProgress,
        loadingIndicatorProgress.value + stepDuration
      )
    }, loadingProgressTimeoutInMilliseconds)
  }

  const startLoadingIndicator = function () {
    // Stop if the progress is already showing
    if (loadingIndicatorProgress.value > minProgress) {
      return
    }

    // Ensure that the loading indicator is starting from scratch
    clearLoadingIndicator()
    loadingIndicatorProgress.value = minProgress

    isLoading.value = true
    throttleTimer.value = setTimeout(() => {
      showLoadingIndicator.value = true
      startTimer()
    }, throttleTimeoutInMilliseconds)
  }
  const finishLoadingIndicator = function () {
    if (loadingIndicatorProgress.value !== minProgress) {
      loadingIndicatorProgress.value = maxProgress
    }
    hideLoadingIndicator()
  }

  return {
    startLoadingIndicator,
    finishLoadingIndicator,
    hideLoadingIndicator,
    clearLoadingIndicator,
    isLoading,
    showLoadingIndicator,
    loadingIndicatorProgress,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingIndicatorStore, import.meta.hot))
}
