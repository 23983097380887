<template>
  <div>
    <nav
      id="main-menu"
      :class="['drop-down-menu main hidden sm:block', { included: menuItems !== undefined }]"
    >
      <ul class="first-level">
        <MenuItem
          v-for="item in menuItems ?? []"
          :key="item.name"
          :item="item"
          @click="handleClick"
        />
      </ul>
    </nav>

    <!-- Burger Menu. Visibility controlled by sm:hidden class-->
    <button
      type="button"
      class="flex items-center gap-x-1 px-3 py-0 text-white sm:hidden"
      @click="open"
    >
      <SvgIcon icon="menu" aria-hidden="true" />
      <span>{{ Translator.trans('u2_core.menu') }}</span>
    </button>
    <!--    End Burger Menu-->
    <div>
      <!--      Off-canvas Navigation Menu -->
      <TransitionRoot as="template" :show="pageStore.offCanvas">
        <Dialog as="div" class="relative z-50 xl:hidden" @close="close">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild
              as="template"
              enter="transition ease-in-out duration-300 transform"
              enter-from="-translate-x-full"
              enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leave-from="translate-x-0"
              leave-to="-translate-x-full"
            >
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild
                  as="template"
                  enter="ease-in-out duration-300"
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="ease-in-out duration-300"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                >
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="close">
                      <span class="sr-only">{{ Translator.trans('u2.close_menu') }}</span>
                      <SvgIcon icon="cross" class="text-white" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div
                  class="flex grow flex-col overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10"
                >
                  <router-link
                    :to="{ name: 'AppHome' }"
                    class="my-5 inline-block self-start text-white no-underline"
                    data-app-logo
                  >
                    <img
                      class="h-[34px] w-auto max-w-[200px] object-contain text-center align-middle font-bold leading-[34px] text-u2"
                      :src="Routing.generate('u2_dynamicassets_corplogo')"
                      alt="U²"
                    />
                  </router-link>
                  <div class="js-off-canvas-navigation-menu off-canvas-navigation-menu -mx-3 my-0">
                    <ul v-if="pageStore.offCanvas" class="first-level" @click="openSubMenu">
                      <MenuItem
                        v-for="item in menuItems ?? []"
                        :key="item.name"
                        :item="item"
                        @click="handleClick"
                      />
                    </ul>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>
    <!--    End Off-canvas Navigation Menu-->

    <AboutDialog
      v-if="isAboutDialogOpen"
      :title="Translator.trans('u2_core.about')"
      @close="isAboutDialogOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import '@js/utilities/drop-down-menu.jquery'
import { computed, nextTick, onMounted, onUpdated, ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useQuery } from '@tanstack/vue-query'
import $ from 'jquery'
import AboutDialog from '@js/components/AboutDialog.vue'
import { queries } from '@js/query'
import Routing from '@js/Routing'
import SvgIcon from '@js/components/SvgIcon.vue'
import Translator from '@js/translator'
import { usePageStore } from '@js/stores/page'
import MenuItem from '@js/components/menu/MenuItem.vue'
import type { MenuItem as MenuItemType } from '@js/model/menu'

const isAboutDialogOpen = ref(false)
function handleClick(menuItem: MenuItemType) {
  if (menuItem.extras.dialog === 'about') {
    isAboutDialogOpen.value = true
  }
}

const { data: mainMenuJson } = useQuery(queries.menu.mainMenuJson)
const menuItems = computed(() => {
  return mainMenuJson.value?.data?.['children']
})

const pageStore = usePageStore()

onUpdated(() => {
  nextTick(() => {
    $('#main-menu').dropDownMenu()
  })
})
onMounted(() => {
  nextTick(() => {
    $('#main-menu').dropDownMenu()
  })
})

const openSubMenu = (event: Event) => {
  const itemClicked = $(event.target as HTMLElement)
  if (itemClicked.hasClass('js-drop-down-menu-trigger')) {
    event.preventDefault()
  }
  itemClicked.toggleClass('open')
  itemClicked.parent().children('ul').slideToggle(300)
}

function close() {
  pageStore.offCanvas = false
}
function open() {
  pageStore.offCanvas = true
}
</script>

<style>
#main-menu.drop-down-menu {
  li,
  a {
    color: theme('colors.white');
  }

  li {
    display: inline-block;
    position: relative;
    transition: background-color 0.3s ease;

    &:hover,
    &.active {
      background-color: theme('colors.gray.800');

      > ul {
        display: block;
      }
    }
  }

  span {
    cursor: pointer;
  }

  a {
    display: block;
    line-height: theme('lineHeight.tight');
    padding: theme('spacing[1.5]') 12px;

    &[class*='icon-']::before {
      position: relative;
      top: -1px;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  ul.first-level {
    white-space: nowrap;

    > li > a,
    > li > span {
      height: var(--app-header-height);
      line-height: var(--app-header-height);
      padding: 0 theme('spacing[1.5]');
    }

    ul {
      border-bottom-left-radius: theme('borderRadius.sm');
      border-bottom-right-radius: theme('borderRadius.sm');
      border-top: 1px solid hsl(0deg 0% 0% / 25%);
      box-shadow: theme('boxShadow.md');
      display: none;
      padding: 5px 0;
      position: absolute;
      top: var(--app-header-height);
      white-space: normal;
      z-index: theme('zIndex.40');

      &,
      .drop-down-menu-separator > span {
        background-color: theme('colors.gray.800');
      }

      li {
        display: block;
        width: auto;

        &:hover,
        &.active {
          background-color: theme('colors.transparent-white-20');
        }
      }

      ul {
        border-top-right-radius: theme('borderRadius.sm');
        left: var(--main-menu-item-width);
        top: -5px;
      }
    }
  }

  .drop-down-menu-arrow-right {
    /* Specificity (".drop-down-menu a") */
    padding-right: 30px !important;

    &::after {
      background-color: theme('colors.white');
      content: '';
      display: inline-block;
      font-size: 1em;
      height: 1em;
      mask-image: url('@icons/arrow-right.svg?url');
      mask-size: cover;
      position: absolute;
      right: 10px;
      top: calc(50% - 5px);
      vertical-align: middle;
      width: 1em;
    }
  }

  .drop-down-menu-separator {
    /* Specificity of the drop-down styles, and removes the need of re-declaring it for :hover */
    background-color: theme('colors.gray.400') !important;
    height: 1px;
    line-height: 10px;
    margin: 5px 10px;
    text-align: center;

    span {
      background-color: theme('colors.gray.700');
      color: theme('colors.gray.200');
      display: inline-block;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 0 10px;
      position: relative;
      top: -5px;
    }
  }

  @media (min-width: theme('screens.sm')) {
    &.main {
      ul.first-level ul {
        width: var(--main-menu-item-width);
      }
    }
  }

  @media (width >= 905px) {
    ul.first-level > li > a,
    ul.first-level > li > span {
      font-size: theme('fontSize.lg');
      letter-spacing: 0.5px;
      padding: 0 9px;
    }
  }
}

.off-canvas-navigation-menu {
  ul {
    padding: 0;
  }

  > ul > li:first-of-type > a,
  > ul > li:first-of-type > span {
    border-top: none;
  }

  > ul ul {
    border-left: 10px solid theme('colors.gray.900');
    display: none;

    &,
    .drop-down-menu-separator > span {
      background: theme('colors.gray.700');
      padding-bottom: 0;
      padding-top: 0;
    }

    .drop-down-menu-separator > span {
      background: theme('colors.gray.700');
      font-variant-caps: all-petite-caps;
      font-weight: bolder;
    }

    ul {
      border-left-color: theme('colors.gray.700');

      &,
      .drop-down-menu-separator > span {
        background: theme('colors.gray.600');
      }

      ul {
        border-left-color: theme('colors.gray.600');

        &,
        .drop-down-menu-separator > span {
          background: theme('colors.gray.500');
        }
      }
    }

    li {
      display: block;
    }
  }

  li {
    list-style: none;
  }

  a,
  span {
    border-bottom: none;
    border-top: 1px solid theme('colors.transparent-white-20');
    clear: both;
    color: theme('colors.white');
    display: block;
    line-height: theme('lineHeight.normal');
    padding: 8px 10px 7px;
    position: relative;

    &:hover {
      background: theme('colors.transparent-white-20');
      text-decoration: none;
    }
  }

  .drop-down-menu-arrow-right {
    &::after {
      display: none;
    }
  }
}
</style>
