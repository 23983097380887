import { StatusCodes } from 'http-status-codes'
import { userApi } from '@js/api/userApi'
import router from '@js/router'
import { createErrorLocation } from '@js/router/helpers'
import { useAuthStore } from '@js/stores/auth'
import type { RouteLocationGeneric } from 'vue-router'

const fetchUser = async (to: RouteLocationGeneric) => {
  const authStore = useAuthStore()

  if (!authStore.hasRole('ROLE_USER_GROUP_ADMIN') && authStore.user?.id !== +to.params.id) {
    await router.push(createErrorLocation(to, StatusCodes.FORBIDDEN))
    return
  }

  const { data: user } = await userApi.fetchById(+to.params.id)
  to.meta.user = user
}
export default fetchUser
