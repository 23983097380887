import axios from 'axios'
import type { Status } from '@js/model/status'
import type { ApiResource, ApiResourceId, HydraCollectionResponse } from '@js/types'
import type { SortingDirection } from '@js/utilities/getNextSortingDirection'

export type WorkflowBinding = ApiResource & {
  id: number
  bindingId: string
  workflow: ApiResourceId
  readableName: string
}
export type Workflow = ApiResource & {
  id: number
  name: string
  initialStatus: Status
  transitions: Array<ApiResourceId>
  bindings: Array<WorkflowBinding>
  manualReviewEnabled: boolean
  checks: Array<ApiResourceId>
  fieldConfigurationStatuses: Array<ApiResourceId>
}

export type WorkflowCollectionQuery = {
  page?: number
  itemsPerPage?: number
  pagination?: boolean
  'sort[name]'?: SortingDirection
  'bindings.bindingId'?: string | Array<string>
  search?: string | Array<string>
}

const basePath = '/api/workflows'

function fetchByBindingId(bindingId: string) {
  return fetchByQuery({ 'bindings.bindingId': bindingId })
}

function fetchById(id: number) {
  return fetchByIri(`${basePath}/${id}`)
}

function fetchByIri(iri: ApiResourceId) {
  return axios.get<Workflow>(iri)
}

function fetchByQuery(query?: WorkflowCollectionQuery) {
  return axios.get<HydraCollectionResponse<Workflow>>(basePath, {
    params: { ...query },
  })
}

export const workflowApi = {
  fetchByBindingId,
  fetchById,
  fetchByIri,
  fetchByQuery,
}
