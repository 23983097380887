import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { importApi } from '@js/api/importApi'
import type { MaybeRef } from '@vueuse/core'
import type { ImportType } from '@js/model/import'

export const importTypes = createQueryKeys('importTypes', {
  all: {
    queryKey: null,
    queryFn: () => {
      return importApi.fetchImportTypes().then((response) => response.data)
    },
  },
  single: (id: MaybeRef<ImportType['shortName']>) => {
    return {
      queryKey: [id],
      queryFn: () => importApi.fetchImportType(unref(id)).then((response) => response.data),
    }
  },
})
