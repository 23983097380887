import { createQueryKeys } from '@lukemorales/query-key-factory'
import { unref } from 'vue'
import { commentApi } from '@js/api/commentApi'

export const comments = createQueryKeys('comments', {
  single: (id) => {
    return {
      queryKey: [id],
      queryFn: () => commentApi.fetchById(unref(id)).then((response) => response.data),
    }
  },
})
