import ConfigurationDataLayout from '@js/layouts/ConfigurationDataLayout.vue'
import Translator from '@js/translator'
import type { RouteLocation, RouteRecordRaw } from 'vue-router'

export default [
  {
    component: () => import('@js/pages/legal-form/LegalFormList.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      globalSearch: {
        icon: 'config',
        name: () => Translator.trans('u2_core.legal_form.plural'),
      },
    },
    name: 'LegalFormList',
    path: '/configuration/legal-form',
  },
  {
    component: () => import('@js/pages/legal-form/LegalFormEdit.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
      title: (params: { id: number }) => `${Translator.trans('u2_core.legal_form')} #${params.id}`,
    },
    name: 'LegalFormEdit',
    path: '/configuration/legal-form/:id/edit',
    props: (route: RouteLocation) => ({ id: +route.params.id }),
  },
  {
    component: () => import('@js/pages/legal-form/LegalFormNew.vue'),
    meta: {
      auth: 'ROLE_ADMIN',
      layout: ConfigurationDataLayout,
    },
    name: 'LegalFormNew',
    path: '/configuration/legal-form/new',
  },
] as Array<RouteRecordRaw>
