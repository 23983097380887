import axios from 'axios'
import Routing from '@js/Routing'
import { documentSectionTypeToShortName } from '@js/model/document'
import { type TaskExtra, type TaskShortName } from '@js/model/task'
import type { MessageBag } from '@js/stores/notifications'
import type { Attachment } from '@js/model/attachment'
import type { DocumentSection } from '@js/model/document'
import type { Country } from '@js/model/country'

export type DocumentEditDataResponse = {
  newSectionPath: string
  attachments: Array<Attachment>
  sections: Array<DocumentSection>
  documentName: string
  sectionsHtml: string
  routes: { edit: string }
  userCanEditContent: boolean
  userCanEditConfiguration: boolean
}

export type DocumentSectionPlacement = 'before' | 'after' | 'subsection-of'

export type NewSectionOptions = {
  placement: DocumentSectionPlacement
  referenceSectionId: DocumentSection['id']
}

function fetchByTask(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<DocumentEditDataResponse>(
    Routing.generate('u2_tasktype_editdocument_data', {
      shortName,
      id,
    })
  )
}

function fetchRenderedSections(shortName: TaskShortName, id: TaskExtra['taskTypeId']) {
  return axios.get<Array<{ id: DocumentSection['id']; content: string }>>(
    Routing.generate('u2_tasktype_renderdocumentsections', {
      shortName,
      id,
    })
  )
}

function addInitialSection(initialSectionData: {
  shortName: TaskShortName
  documentId: TaskExtra['taskTypeId']
  name: DocumentSection['name']
  content?: DocumentSection['content']
}) {
  const sectionFormData = new FormData()
  sectionFormData.append(
    'document_section_initial_add_form[sectionToManipulate][name]',
    initialSectionData.name
  )
  if (initialSectionData.content) {
    sectionFormData.append(
      'document_section_initial_add_form[sectionToManipulate][content]',
      initialSectionData.content
    )
  }

  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_newinitialsection', {
      shortName: initialSectionData.shortName,
      documentId: initialSectionData.documentId,
    }),
    sectionFormData
  )
}

function addSection(
  section: Partial<DocumentSection> & Pick<DocumentSection, '@type' | 'name'>,
  options: NewSectionOptions
) {
  const { placement, referenceSectionId } = options
  const sectionFormData = new FormData()
  sectionFormData.append('document_section_add_form[sectionToManipulate][name]', section.name)
  return axios.post<{ success: boolean; messages: MessageBag }>(
    Routing.generate('u2_documentsection_create', {
      placement,
      referenceSectionId,
      shortName: documentSectionTypeToShortName[section['@type']],
    }),
    sectionFormData
  )
}

function downloadOecdReport(taskTypeId: TaskExtra['taskTypeId']) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/xbrl-download`,
    {}
  )
}

function downloadBmfReport(taskTypeId: TaskExtra['taskTypeId'], countryId: Country['id']) {
  return axios.post<{ xml: string; name: string }>(
    `/api/country-by-country-reports/${taskTypeId}/country/${countryId}/xbrl-download`,
    {}
  )
}

export const documentApi = {
  downloadOecdReport,
  addInitialSection,
  addSection,
  fetchByTask,
  fetchRenderedSections,
  downloadBmfReport,
}
